import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $insertNodeToNearestRoot } from '@lexical/utils';
import { COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from 'lexical';
import { useEffect } from 'react';
import { $createCalculationNode, CalculationNode } from '../nodes/calculation-node/CalculationNode';

export type InsertCalculationPayload = { recipeId: number; calculationId: number; versionId: number };

export const INSERT_CALCULATION_COMMAND: LexicalCommand<InsertCalculationPayload> =
  createCommand('INSERT_CALCULATION_COMMAND');

export default function CalculationPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([CalculationNode])) {
      throw new Error('YouTubePlugin: YouTubeNode not registered on editor');
    }

    return editor.registerCommand<InsertCalculationPayload>(
      INSERT_CALCULATION_COMMAND,
      (payload) => {
        const youTubeNode = $createCalculationNode(payload.recipeId, payload.calculationId, payload.versionId);
        $insertNodeToNearestRoot(youTubeNode);

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor]);

  return null;
}
