//-----Types.File-----
export interface ProblemDetails  {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}
export function deserializeProblemDetails(json: string): ProblemDetails {
  const data = JSON.parse(json) as ProblemDetails;
  initProblemDetails(data);
  return data;
}
export function initProblemDetails(_data: ProblemDetails) {
    return _data;
}
export function serializeProblemDetails(_data: ProblemDetails | undefined) {
  if (_data) {
    _data = prepareSerializeProblemDetails(_data as ProblemDetails);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeProblemDetails(_data: ProblemDetails): ProblemDetails {
  const data: Record<string, any> = { ..._data };
  return data as ProblemDetails;
}
export interface HttpValidationProblemDetails extends ProblemDetails  {
  errors: { [key: string]: string[]; };
  [key: string]: any;
}
export function deserializeHttpValidationProblemDetails(json: string): HttpValidationProblemDetails {
  const data = JSON.parse(json) as HttpValidationProblemDetails;
  initHttpValidationProblemDetails(data);
  return data;
}
export function initHttpValidationProblemDetails(_data: HttpValidationProblemDetails) {
  initProblemDetails(_data);
    return _data;
}
export function serializeHttpValidationProblemDetails(_data: HttpValidationProblemDetails | undefined) {
  if (_data) {
    _data = prepareSerializeHttpValidationProblemDetails(_data as HttpValidationProblemDetails);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeHttpValidationProblemDetails(_data: HttpValidationProblemDetails): HttpValidationProblemDetails {
  const data = prepareSerializeProblemDetails(_data as HttpValidationProblemDetails) as Record<string, any>;
  return data as HttpValidationProblemDetails;
}
export interface ValidationProblemDetails extends HttpValidationProblemDetails  {
  errors: { [key: string]: string[]; };
  [key: string]: any;
}
export function deserializeValidationProblemDetails(json: string): ValidationProblemDetails {
  const data = JSON.parse(json) as ValidationProblemDetails;
  initValidationProblemDetails(data);
  return data;
}
export function initValidationProblemDetails(_data: ValidationProblemDetails) {
  initHttpValidationProblemDetails(_data);
    return _data;
}
export function serializeValidationProblemDetails(_data: ValidationProblemDetails | undefined) {
  if (_data) {
    _data = prepareSerializeValidationProblemDetails(_data as ValidationProblemDetails);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeValidationProblemDetails(_data: ValidationProblemDetails): ValidationProblemDetails {
  const data = prepareSerializeHttpValidationProblemDetails(_data as ValidationProblemDetails) as Record<string, any>;
  return data as ValidationProblemDetails;
}
export interface UserSettingsDto  {
  /** Indicates if user wants to see only materials from dropdown in calculator. */
  inventoryMaterialsOnly: boolean;
}
export function deserializeUserSettingsDto(json: string): UserSettingsDto {
  const data = JSON.parse(json) as UserSettingsDto;
  initUserSettingsDto(data);
  return data;
}
export function initUserSettingsDto(_data: UserSettingsDto) {
    return _data;
}
export function serializeUserSettingsDto(_data: UserSettingsDto | undefined) {
  if (_data) {
    _data = prepareSerializeUserSettingsDto(_data as UserSettingsDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeUserSettingsDto(_data: UserSettingsDto): UserSettingsDto {
  const data: Record<string, any> = { ..._data };
  return data as UserSettingsDto;
}
export interface SubscriptionOptionDto  {
  /** Level of the subscription. */
  subscriptionLevel: string;
  /** Maximum number of the photos, that user allowed to upload. */
  maxPhotosCount: number | null;
  /** Maximum recipe calculators in one recipe. */
  maxRecipeCalculatorsPerRecipeCount: number | null;
  /** Cost of the subscription per month. (in rubles) */
  costInRublesPerMonth: number;
  /** Collected sum by donations. */
  collectedAmountOfMoney: number;
  /** Shows whether user with such subscription is allowed to create/edit investigations. */
  additionalInvestigationFeaturesAccess: boolean;
}
export function deserializeSubscriptionOptionDto(json: string): SubscriptionOptionDto {
  const data = JSON.parse(json) as SubscriptionOptionDto;
  initSubscriptionOptionDto(data);
  return data;
}
export function initSubscriptionOptionDto(_data: SubscriptionOptionDto) {
    return _data;
}
export function serializeSubscriptionOptionDto(_data: SubscriptionOptionDto | undefined) {
  if (_data) {
    _data = prepareSerializeSubscriptionOptionDto(_data as SubscriptionOptionDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeSubscriptionOptionDto(_data: SubscriptionOptionDto): SubscriptionOptionDto {
  const data: Record<string, any> = { ..._data };
  return data as SubscriptionOptionDto;
}
/** Represents recipe's information, such as name/description. */
export interface RecipeInfoDto  {
  /** Id of the Recipe in the system. */
  id: number;
  /** Name of the recipe. */
  name: string;
  /** Description of the recipe. */
  description: string;
  /** Datetime, when recipe was updated.
Also changes, when any updates of  have been happened. */
  updatedAt: Date;
  /** Shows whether the recipe will be available for other users for read only. */
  published: boolean;
}
export function deserializeRecipeInfoDto(json: string): RecipeInfoDto {
  const data = JSON.parse(json) as RecipeInfoDto;
  initRecipeInfoDto(data);
  return data;
}
export function initRecipeInfoDto(_data: RecipeInfoDto) {
  if (_data) {
    _data.updatedAt = _data["updatedAt"] ? new Date(_data["updatedAt"].toString()) : <any>null;
  }
  return _data;
}
export function serializeRecipeInfoDto(_data: RecipeInfoDto | undefined) {
  if (_data) {
    _data = prepareSerializeRecipeInfoDto(_data as RecipeInfoDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeRecipeInfoDto(_data: RecipeInfoDto): RecipeInfoDto {
  const data: Record<string, any> = { ..._data };
  data["updatedAt"] = _data.updatedAt && _data.updatedAt.toISOString();
  return data as RecipeInfoDto;
}
/** Dto for recipe creation. */
export interface CreateRecipeDto  {
  /** Name of the recipe. */
  name: string;
  /** Description of the recipe. */
  description?: string | null;
  /** Id of recipe folder, where new recipe should be located. */
  recipeFolderId: number;
}
export function deserializeCreateRecipeDto(json: string): CreateRecipeDto {
  const data = JSON.parse(json) as CreateRecipeDto;
  initCreateRecipeDto(data);
  return data;
}
export function initCreateRecipeDto(_data: CreateRecipeDto) {
    return _data;
}
export function serializeCreateRecipeDto(_data: CreateRecipeDto | undefined) {
  if (_data) {
    _data = prepareSerializeCreateRecipeDto(_data as CreateRecipeDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeCreateRecipeDto(_data: CreateRecipeDto): CreateRecipeDto {
  const data: Record<string, any> = { ..._data };
  return data as CreateRecipeDto;
}
/** Dto for recipe update. */
export interface UpdateRecipeDto  {
  /** Name of the recipe. */
  name: string;
  /** Description of the recipe. */
  description?: string | null;
}
export function deserializeUpdateRecipeDto(json: string): UpdateRecipeDto {
  const data = JSON.parse(json) as UpdateRecipeDto;
  initUpdateRecipeDto(data);
  return data;
}
export function initUpdateRecipeDto(_data: UpdateRecipeDto) {
    return _data;
}
export function serializeUpdateRecipeDto(_data: UpdateRecipeDto | undefined) {
  if (_data) {
    _data = prepareSerializeUpdateRecipeDto(_data as UpdateRecipeDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeUpdateRecipeDto(_data: UpdateRecipeDto): UpdateRecipeDto {
  const data: Record<string, any> = { ..._data };
  return data as UpdateRecipeDto;
}
/** Represents recipe's data with assigned recipe calculations. */
export interface RecipeDto extends RecipeInfoDto  {
  /** Ids of the current Recipe's photos. */
  photos: RecipePhotoDto[];
  /** True when the recipe is published by an author and doesn't belong to user, who requested it. */
  isReadonly: boolean;
  /** Count of users, who viewed the recipe in published state. */
  viewedByUsersCount: number;
}
export function deserializeRecipeDto(json: string): RecipeDto {
  const data = JSON.parse(json) as RecipeDto;
  initRecipeDto(data);
  return data;
}
export function initRecipeDto(_data: RecipeDto) {
  initRecipeInfoDto(_data);
  if (_data) {
    if (Array.isArray(_data["photos"])) {
      _data.photos = _data["photos"].map(item => 
        initRecipePhotoDto(item)
      );
    }
  }
  return _data;
}
export function serializeRecipeDto(_data: RecipeDto | undefined) {
  if (_data) {
    _data = prepareSerializeRecipeDto(_data as RecipeDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeRecipeDto(_data: RecipeDto): RecipeDto {
  const data = prepareSerializeRecipeInfoDto(_data as RecipeDto) as Record<string, any>;
  if (Array.isArray(_data.photos)) {
    data["photos"] = _data.photos.map(item => 
        prepareSerializeRecipePhotoDto(item)
    );
  }
  return data as RecipeDto;
}
/** Contains info of a recipe's photo. */
export interface RecipePhotoDto  {
  /** Id of the recipe's photo. */
  id: string;
  /** Description of the recipe's photo. */
  description?: string | null;
  /** Date and time when the photo was added to the recipe. */
  createdAt: Date;
}
export function deserializeRecipePhotoDto(json: string): RecipePhotoDto {
  const data = JSON.parse(json) as RecipePhotoDto;
  initRecipePhotoDto(data);
  return data;
}
export function initRecipePhotoDto(_data: RecipePhotoDto) {
  if (_data) {
    _data.createdAt = _data["createdAt"] ? new Date(_data["createdAt"].toString()) : <any>null;
  }
  return _data;
}
export function serializeRecipePhotoDto(_data: RecipePhotoDto | undefined) {
  if (_data) {
    _data = prepareSerializeRecipePhotoDto(_data as RecipePhotoDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeRecipePhotoDto(_data: RecipePhotoDto): RecipePhotoDto {
  const data: Record<string, any> = { ..._data };
  data["createdAt"] = _data.createdAt && _data.createdAt.toISOString();
  return data as RecipePhotoDto;
}
/** Dto with prepared data for export. */
export interface PrintRecipeDto  {
  /** Id of the Recipe. */
  recipeId: number;
  /** Calculations of the Recipe. */
  calculations: PrintRecipeCalculationDto[];
  /** Investigation of the Recipe. */
  investigation: InvestigationDto;
}
export function deserializePrintRecipeDto(json: string): PrintRecipeDto {
  const data = JSON.parse(json) as PrintRecipeDto;
  initPrintRecipeDto(data);
  return data;
}
export function initPrintRecipeDto(_data: PrintRecipeDto) {
  if (_data) {
    if (Array.isArray(_data["calculations"])) {
      _data.calculations = _data["calculations"].map(item => 
        initPrintRecipeCalculationDto(item)
      );
    }
    _data.investigation = _data["investigation"] && initInvestigationDto(_data["investigation"]);
  }
  return _data;
}
export function serializePrintRecipeDto(_data: PrintRecipeDto | undefined) {
  if (_data) {
    _data = prepareSerializePrintRecipeDto(_data as PrintRecipeDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializePrintRecipeDto(_data: PrintRecipeDto): PrintRecipeDto {
  const data: Record<string, any> = { ..._data };
  if (Array.isArray(_data.calculations)) {
    data["calculations"] = _data.calculations.map(item => 
        prepareSerializePrintRecipeCalculationDto(item)
    );
  }
  data["investigation"] = _data.investigation && prepareSerializeInvestigationDto(_data.investigation);
  return data as PrintRecipeDto;
}
/** Dto, which contains all recipes as recipe calculation for export all data functionality. */
export interface PrintRecipeCalculationDto  {
  /** Name of the Recipe. */
  recipeName: string;
  /** Id of the Recipe. */
  recipeId: number;
  /** Name of the Calculation. */
  recipeCalculationName: string;
  /** Id of the Calculation. */
  recipeCalculationId: number;
  /** Name of the folder, where Recipe is located. */
  recipeFolderName: string;
  /** Datetime, when recipe was updated. */
  recipeLastUpdatedAt: Date;
  /** Calculated recipe calculation for batch. */
  recipe: BatchIngredientDto[];
  /** Sum of values in the recipe, without additional ingredients. */
  baseValuesSum: number;
  /** Sum of all ingredients values in the recipe. */
  overallValuesSum: number;
  /** Sum of values in the recipe, without additional ingredients.
Null in case, when batch shouldn't be printed. */
  baseBatchValuesSum: number | null;
  /** Sum of all ingredients batch values in the recipe.
Null in case, when batch shouldn't be printed. */
  overallBatchValuesSum: number | null;
}
export function deserializePrintRecipeCalculationDto(json: string): PrintRecipeCalculationDto {
  const data = JSON.parse(json) as PrintRecipeCalculationDto;
  initPrintRecipeCalculationDto(data);
  return data;
}
export function initPrintRecipeCalculationDto(_data: PrintRecipeCalculationDto) {
  if (_data) {
    _data.recipeLastUpdatedAt = _data["recipeLastUpdatedAt"] ? new Date(_data["recipeLastUpdatedAt"].toString()) : <any>null;
    if (Array.isArray(_data["recipe"])) {
      _data.recipe = _data["recipe"].map(item => 
        initBatchIngredientDto(item)
      );
    }
  }
  return _data;
}
export function serializePrintRecipeCalculationDto(_data: PrintRecipeCalculationDto | undefined) {
  if (_data) {
    _data = prepareSerializePrintRecipeCalculationDto(_data as PrintRecipeCalculationDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializePrintRecipeCalculationDto(_data: PrintRecipeCalculationDto): PrintRecipeCalculationDto {
  const data: Record<string, any> = { ..._data };
  data["recipeLastUpdatedAt"] = _data.recipeLastUpdatedAt && _data.recipeLastUpdatedAt.toISOString();
  if (Array.isArray(_data.recipe)) {
    data["recipe"] = _data.recipe.map(item => 
        prepareSerializeBatchIngredientDto(item)
    );
  }
  return data as PrintRecipeCalculationDto;
}
export interface IngredientDto  {
  /** Material info. */
  material: MaterialBaseDto;
  /** Amount of material that was used. */
  value: number;
  /** Shows whether the ingredient is additional in the recipe.
Additional ingredients doesn't influence on reduction recipe to 100. */
  isAddition: boolean;
}
export function deserializeIngredientDto(json: string): IngredientDto {
  const data = JSON.parse(json) as IngredientDto;
  initIngredientDto(data);
  return data;
}
export function initIngredientDto(_data: IngredientDto) {
  if (_data) {
    _data.material = _data["material"] && initMaterialBaseDto(_data["material"]);
  }
  return _data;
}
export function serializeIngredientDto(_data: IngredientDto | undefined) {
  if (_data) {
    _data = prepareSerializeIngredientDto(_data as IngredientDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeIngredientDto(_data: IngredientDto): IngredientDto {
  const data: Record<string, any> = { ..._data };
  data["material"] = _data.material && prepareSerializeMaterialBaseDto(_data.material);
  return data as IngredientDto;
}
export interface BatchIngredientDto extends IngredientDto  {
  /** Amount of material that should be mixed for a batch.
Zero, when batch value is not needed. */
  batchValue: number;
}
export function deserializeBatchIngredientDto(json: string): BatchIngredientDto {
  const data = JSON.parse(json) as BatchIngredientDto;
  initBatchIngredientDto(data);
  return data;
}
export function initBatchIngredientDto(_data: BatchIngredientDto) {
  initIngredientDto(_data);
    return _data;
}
export function serializeBatchIngredientDto(_data: BatchIngredientDto | undefined) {
  if (_data) {
    _data = prepareSerializeBatchIngredientDto(_data as BatchIngredientDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeBatchIngredientDto(_data: BatchIngredientDto): BatchIngredientDto {
  const data = prepareSerializeIngredientDto(_data as BatchIngredientDto) as Record<string, any>;
  return data as BatchIngredientDto;
}
/** Represents material's minimal data. */
export interface MaterialBaseDto  {
  /** Id of the material in the system. */
  id: number;
  /** Name of the material. */
  name: string;
  /** Indicates whether the material is public (available for all users). */
  isPublic: boolean;
  /** Indicates whether the Material is in user's inventory. */
  inInventory: boolean;
}
export function deserializeMaterialBaseDto(json: string): MaterialBaseDto {
  const data = JSON.parse(json) as MaterialBaseDto;
  initMaterialBaseDto(data);
  return data;
}
export function initMaterialBaseDto(_data: MaterialBaseDto) {
    return _data;
}
export function serializeMaterialBaseDto(_data: MaterialBaseDto | undefined) {
  if (_data) {
    _data = prepareSerializeMaterialBaseDto(_data as MaterialBaseDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeMaterialBaseDto(_data: MaterialBaseDto): MaterialBaseDto {
  const data: Record<string, any> = { ..._data };
  return data as MaterialBaseDto;
}
export interface InvestigationDto  {
  /** Id of the Investigation. */
  id: number | null;
  /** Text of the Investigation. */
  text: string | null;
  /** Name of the Recipe */
  recipeName: string;
  /** Description of the Recipe */
  recipeDescription: string;
  /** Shows whether the Investigation is readonly. */
  isReadonly: boolean;
}
export function deserializeInvestigationDto(json: string): InvestigationDto {
  const data = JSON.parse(json) as InvestigationDto;
  initInvestigationDto(data);
  return data;
}
export function initInvestigationDto(_data: InvestigationDto) {
    return _data;
}
export function serializeInvestigationDto(_data: InvestigationDto | undefined) {
  if (_data) {
    _data = prepareSerializeInvestigationDto(_data as InvestigationDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeInvestigationDto(_data: InvestigationDto): InvestigationDto {
  const data: Record<string, any> = { ..._data };
  return data as InvestigationDto;
}
export enum ResizeMode {
    Crop = "Crop",
    Pad = "Pad",
    BoxPad = "BoxPad",
    Max = "Max",
    Min = "Min",
    Stretch = "Stretch",
    Manual = "Manual",
}
/** Represents recipe folders' data. */
export interface RecipeFolderDto  {
  /** Id of the RecipeFolder in the system. */
  id: number;
  /** Name of the RecipeFolder. */
  name: string;
  /** Id of recipes in this RecipeFolder. */
  recipes: RecipeInfoDto[];
  /** Type of recipes sorting in the RecipeFolder */
  recipesSortingType: SortingType;
  /** Direction of recipes sorting in the RecipeFolder */
  recipesSortingDirection: SortDirection;
  /** Represents type of the recipe folder. */
  recipeFolderType: RecipeFolderType;
  /** Public Id of the RecipeFolder. */
  publicId: string;
}
export function deserializeRecipeFolderDto(json: string): RecipeFolderDto {
  const data = JSON.parse(json) as RecipeFolderDto;
  initRecipeFolderDto(data);
  return data;
}
export function initRecipeFolderDto(_data: RecipeFolderDto) {
  if (_data) {
    if (Array.isArray(_data["recipes"])) {
      _data.recipes = _data["recipes"].map(item => 
        initRecipeInfoDto(item)
      );
    }
    _data.recipesSortingType = _data["recipesSortingType"];
    _data.recipesSortingDirection = _data["recipesSortingDirection"];
    _data.recipeFolderType = _data["recipeFolderType"];
  }
  return _data;
}
export function serializeRecipeFolderDto(_data: RecipeFolderDto | undefined) {
  if (_data) {
    _data = prepareSerializeRecipeFolderDto(_data as RecipeFolderDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeRecipeFolderDto(_data: RecipeFolderDto): RecipeFolderDto {
  const data: Record<string, any> = { ..._data };
  if (Array.isArray(_data.recipes)) {
    data["recipes"] = _data.recipes.map(item => 
        prepareSerializeRecipeInfoDto(item)
    );
  }
  return data as RecipeFolderDto;
}
export enum SortingType {
    SortByName = "SortByName",
    SortByCreatedAt = "SortByCreatedAt",
}
export enum SortDirection {
    Ascending = "Ascending",
    Descending = "Descending",
}
export enum RecipeFolderType {
    Private = "Private",
    CalculationTemplate = "CalculationTemplate",
}
/** Dto for RecipeFolder creation or update. */
export interface CreateOrUpdateRecipeFolderDto  {
  /** Name of the RecipeFolder. */
  name: string;
}
export function deserializeCreateOrUpdateRecipeFolderDto(json: string): CreateOrUpdateRecipeFolderDto {
  const data = JSON.parse(json) as CreateOrUpdateRecipeFolderDto;
  initCreateOrUpdateRecipeFolderDto(data);
  return data;
}
export function initCreateOrUpdateRecipeFolderDto(_data: CreateOrUpdateRecipeFolderDto) {
    return _data;
}
export function serializeCreateOrUpdateRecipeFolderDto(_data: CreateOrUpdateRecipeFolderDto | undefined) {
  if (_data) {
    _data = prepareSerializeCreateOrUpdateRecipeFolderDto(_data as CreateOrUpdateRecipeFolderDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeCreateOrUpdateRecipeFolderDto(_data: CreateOrUpdateRecipeFolderDto): CreateOrUpdateRecipeFolderDto {
  const data: Record<string, any> = { ..._data };
  return data as CreateOrUpdateRecipeFolderDto;
}
/** Represents public recipe folders' data. */
export interface RecipePublicFolderDto extends RecipeFolderDto  {
  /** Full name of the User, who owns the RecipeFolder. */
  ownerFullName: string;
}
export function deserializeRecipePublicFolderDto(json: string): RecipePublicFolderDto {
  const data = JSON.parse(json) as RecipePublicFolderDto;
  initRecipePublicFolderDto(data);
  return data;
}
export function initRecipePublicFolderDto(_data: RecipePublicFolderDto) {
  initRecipeFolderDto(_data);
    return _data;
}
export function serializeRecipePublicFolderDto(_data: RecipePublicFolderDto | undefined) {
  if (_data) {
    _data = prepareSerializeRecipePublicFolderDto(_data as RecipePublicFolderDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeRecipePublicFolderDto(_data: RecipePublicFolderDto): RecipePublicFolderDto {
  const data = prepareSerializeRecipeFolderDto(_data as RecipePublicFolderDto) as Record<string, any>;
  return data as RecipePublicFolderDto;
}
export interface RecipeCalculationBaseDto  {
  /** Chemical formula of the glaze, that is represented as percents molar.
Stored as dictionary, where key is  and value is percentage of oxide in the material.
Null if can't be calculated. */
  formula: { [key in keyof typeof OxideRoles]?: { [key in keyof typeof Oxide]?: number; }; };
  /** Point to display on Stull chart. */
  stullChartPoint: StullChartPointDto;
  /** Sum of Recipe values without additional ingredients. */
  ingredientsOverallSum: number;
  /** Sum of Alcali oxides in Seger's formula. */
  alcaliSum: number | null;
  /** Sum of AEarth oxides in Seger's formula. */
  aEarthSum: number | null;
  /** Ratio of SiO2 to Al2O3. */
  siliconAluminumOxidesRatio: number;
  /** Calculate molar thermal expansion of the recipe. */
  molarThermalExpansion: number;
  /** Calculate molar thermal expansion of the recipe. */
  molecularMassThermalExpansion: number;
}
export function deserializeRecipeCalculationBaseDto(json: string): RecipeCalculationBaseDto {
  const data = JSON.parse(json) as RecipeCalculationBaseDto;
  initRecipeCalculationBaseDto(data);
  return data;
}
export function initRecipeCalculationBaseDto(_data: RecipeCalculationBaseDto) {
  if (_data) {
    _data.stullChartPoint = _data["stullChartPoint"] && initStullChartPointDto(_data["stullChartPoint"]);
  }
  return _data;
}
export function serializeRecipeCalculationBaseDto(_data: RecipeCalculationBaseDto | undefined) {
  if (_data) {
    _data = prepareSerializeRecipeCalculationBaseDto(_data as RecipeCalculationBaseDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeRecipeCalculationBaseDto(_data: RecipeCalculationBaseDto): RecipeCalculationBaseDto {
  const data: Record<string, any> = { ..._data };
  data["stullChartPoint"] = _data.stullChartPoint && prepareSerializeStullChartPointDto(_data.stullChartPoint);
  return data as RecipeCalculationBaseDto;
}
export enum OxideRoles {
    Alcali = "Alcali",
    AEarth = "AEarth",
    Stabs = "Stabs",
    GFormers = "GFormers",
    Other = "Other",
}
export enum Oxide {
    Loi = "Loi",
    SiO2 = "SiO2",
    Al2O3 = "Al2O3",
    B2O3 = "B2O3",
    Li2O = "Li2O",
    Na2O = "Na2O",
    K2O = "K2O",
    BeO = "BeO",
    MgO = "MgO",
    CaO = "CaO",
    SrO = "SrO",
    BaO = "BaO",
    P2O5 = "P2O5",
    TiO2 = "TiO2",
    ZrO = "ZrO",
    ZrO2 = "ZrO2",
    V2O5 = "V2O5",
    Cr2O3 = "Cr2O3",
    MnO = "MnO",
    MnO2 = "MnO2",
    FeO = "FeO",
    Fe2O3 = "Fe2O3",
    CoO = "CoO",
    NiO = "NiO",
    CuO = "CuO",
    Cu2O = "Cu2O",
    CdO = "CdO",
    ZnO = "ZnO",
    F = "F",
    PbO = "PbO",
    SnO2 = "SnO2",
    HfO2 = "HfO2",
    Nb2O5 = "Nb2O5",
    Ta2O5 = "Ta2O5",
    MoO3 = "MoO3",
    WO3 = "WO3",
    OsO2 = "OsO2",
    IrO2 = "IrO2",
    PtO2 = "PtO2",
    Ag2O = "Ag2O",
    Au2O3 = "Au2O3",
    GeO2 = "GeO2",
    As2O3 = "As2O3",
    Sb2O3 = "Sb2O3",
    Bi2O3 = "Bi2O3",
    SeO2 = "SeO2",
    La2O3 = "La2O3",
    CeO2 = "CeO2",
    PrO2 = "PrO2",
    Pr2O3 = "Pr2O3",
    Nd2O3 = "Nd2O3",
    U3O8 = "U3O8",
    Sm2O3 = "Sm2O3",
    Eu2O3 = "Eu2O3",
    Tb2O3 = "Tb2O3",
    Dy2O3 = "Dy2O3",
    Ho2O3 = "Ho2O3",
    Er2O3 = "Er2O3",
    Tm2O3 = "Tm2O3",
    Yb2O3 = "Yb2O3",
    Lu2O3 = "Lu2O3",
    Gd2O3 = "Gd2O3",
    Y2O3 = "Y2O3",
    Tl2O3 = "Tl2O3",
    Ga2O3 = "Ga2O3",
}
/** Represents point on Stull chart. */
export interface StullChartPointDto  {
  /** Value of the Al2O3. */
  al2O3Value: number;
  /** Value of the SiO2. */
  siO2Value: number;
}
export function deserializeStullChartPointDto(json: string): StullChartPointDto {
  const data = JSON.parse(json) as StullChartPointDto;
  initStullChartPointDto(data);
  return data;
}
export function initStullChartPointDto(_data: StullChartPointDto) {
    return _data;
}
export function serializeStullChartPointDto(_data: StullChartPointDto | undefined) {
  if (_data) {
    _data = prepareSerializeStullChartPointDto(_data as StullChartPointDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeStullChartPointDto(_data: StullChartPointDto): StullChartPointDto {
  const data: Record<string, any> = { ..._data };
  return data as StullChartPointDto;
}
/** Data for on the fly calculation. */
export interface CalculateOnTheFlyDto  {
  /** Recipe that should be calculated. */
  recipe: IngredientForCalculationDto[];
  /** Selected view type of the formula. */
  formulaViewType: FormulaViewType;
  /** In case of true, additional ingredients would be included into calculation. */
  includeAdditionsIntoCalculations: boolean;
}
export function deserializeCalculateOnTheFlyDto(json: string): CalculateOnTheFlyDto {
  const data = JSON.parse(json) as CalculateOnTheFlyDto;
  initCalculateOnTheFlyDto(data);
  return data;
}
export function initCalculateOnTheFlyDto(_data: CalculateOnTheFlyDto) {
  if (_data) {
    if (Array.isArray(_data["recipe"])) {
      _data.recipe = _data["recipe"].map(item => 
        initIngredientForCalculationDto(item)
      );
    }
    _data.formulaViewType = _data["formulaViewType"];
  }
  return _data;
}
export function serializeCalculateOnTheFlyDto(_data: CalculateOnTheFlyDto | undefined) {
  if (_data) {
    _data = prepareSerializeCalculateOnTheFlyDto(_data as CalculateOnTheFlyDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeCalculateOnTheFlyDto(_data: CalculateOnTheFlyDto): CalculateOnTheFlyDto {
  const data: Record<string, any> = { ..._data };
  if (Array.isArray(_data.recipe)) {
    data["recipe"] = _data.recipe.map(item => 
        prepareSerializeIngredientForCalculationDto(item)
    );
  }
  return data as CalculateOnTheFlyDto;
}
export interface IngredientForCalculationDto  {
  /** Id of the material. */
  materialId: number;
  /** Amount of material that was used. */
  value: number;
  /** Shows whether the ingredient is additional in the recipe.
Additional ingredients doesn't influence on reduction recipe to 100. */
  isAddition: boolean;
}
export function deserializeIngredientForCalculationDto(json: string): IngredientForCalculationDto {
  const data = JSON.parse(json) as IngredientForCalculationDto;
  initIngredientForCalculationDto(data);
  return data;
}
export function initIngredientForCalculationDto(_data: IngredientForCalculationDto) {
    return _data;
}
export function serializeIngredientForCalculationDto(_data: IngredientForCalculationDto | undefined) {
  if (_data) {
    _data = prepareSerializeIngredientForCalculationDto(_data as IngredientForCalculationDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeIngredientForCalculationDto(_data: IngredientForCalculationDto): IngredientForCalculationDto {
  const data: Record<string, any> = { ..._data };
  return data as IngredientForCalculationDto;
}
export enum FormulaViewType {
    SegerFormula = "SegerFormula",
    PercentsMolar = "PercentsMolar",
}
export interface RecipeCalculationDto  {
  /** Id of the Calculation in the system. */
  id: number;
  /** Latest name of the Calculation in the system. */
  name: string;
  /** Datetime, when the Calculation was created. */
  createdAt: Date;
  /** Selected formula view type, to display in recipe calculation view. */
  formulaViewType: FormulaViewType;
  /** Shows whether recipe is locked for editing. */
  isLocked: boolean;
  /** In case of true, additional ingredients would be included into calculation. */
  includeAdditionsIntoCalculations: boolean;
  /** Ids of calculation versions in historical order. */
  historyRecordsIds: CalculationHistoryRecordHeaderDto[];
  /** Datetime, when the current version of the Calculation was created. */
  versionCreatedAt: Date;
  /** Shows if the calculation was deleted */
  isDeleted: boolean;
}
export function deserializeRecipeCalculationDto(json: string): RecipeCalculationDto {
  const data = JSON.parse(json) as RecipeCalculationDto;
  initRecipeCalculationDto(data);
  return data;
}
export function initRecipeCalculationDto(_data: RecipeCalculationDto) {
  if (_data) {
    _data.createdAt = _data["createdAt"] ? new Date(_data["createdAt"].toString()) : <any>null;
    _data.formulaViewType = _data["formulaViewType"];
    if (Array.isArray(_data["historyRecordsIds"])) {
      _data.historyRecordsIds = _data["historyRecordsIds"].map(item => 
        initCalculationHistoryRecordHeaderDto(item)
      );
    }
    _data.versionCreatedAt = _data["versionCreatedAt"] ? new Date(_data["versionCreatedAt"].toString()) : <any>null;
  }
  return _data;
}
export function serializeRecipeCalculationDto(_data: RecipeCalculationDto | undefined) {
  if (_data) {
    _data = prepareSerializeRecipeCalculationDto(_data as RecipeCalculationDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeRecipeCalculationDto(_data: RecipeCalculationDto): RecipeCalculationDto {
  const data: Record<string, any> = { ..._data };
  data["createdAt"] = _data.createdAt && _data.createdAt.toISOString();
  if (Array.isArray(_data.historyRecordsIds)) {
    data["historyRecordsIds"] = _data.historyRecordsIds.map(item => 
        prepareSerializeCalculationHistoryRecordHeaderDto(item)
    );
  }
  data["versionCreatedAt"] = _data.versionCreatedAt && _data.versionCreatedAt.toISOString();
  return data as RecipeCalculationDto;
}
export interface CalculationHistoryRecordHeaderDto  {
  /** Id of the Calculation in the system. */
  id: number;
  /** Datetime, when the Calculation was created. */
  createdAt: Date;
}
export function deserializeCalculationHistoryRecordHeaderDto(json: string): CalculationHistoryRecordHeaderDto {
  const data = JSON.parse(json) as CalculationHistoryRecordHeaderDto;
  initCalculationHistoryRecordHeaderDto(data);
  return data;
}
export function initCalculationHistoryRecordHeaderDto(_data: CalculationHistoryRecordHeaderDto) {
  if (_data) {
    _data.createdAt = _data["createdAt"] ? new Date(_data["createdAt"].toString()) : <any>null;
  }
  return _data;
}
export function serializeCalculationHistoryRecordHeaderDto(_data: CalculationHistoryRecordHeaderDto | undefined) {
  if (_data) {
    _data = prepareSerializeCalculationHistoryRecordHeaderDto(_data as CalculationHistoryRecordHeaderDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeCalculationHistoryRecordHeaderDto(_data: CalculationHistoryRecordHeaderDto): CalculationHistoryRecordHeaderDto {
  const data: Record<string, any> = { ..._data };
  data["createdAt"] = _data.createdAt && _data.createdAt.toISOString();
  return data as CalculationHistoryRecordHeaderDto;
}
/** Dto for new calculation creation. */
export interface CreateUpdateRecipeCalculationDto  {
  /** Name of the calculation. */
  name: string;
  /** Chemical formula of the calculation.
Stored as dictionary, where key is Oxide and value is percentage of oxide in the material. */
  recipe: IngredientForCalculationDto[];
  /** Selected formula view type, to display in recipe calculation view. */
  formulaViewType: FormulaViewType;
  /** Represents whether recipe is locked for editing. */
  isLocked: boolean;
  /** In case of true, additional ingredients would be included into calculation. */
  includeAdditionsIntoCalculations: boolean;
}
export function deserializeCreateUpdateRecipeCalculationDto(json: string): CreateUpdateRecipeCalculationDto {
  const data = JSON.parse(json) as CreateUpdateRecipeCalculationDto;
  initCreateUpdateRecipeCalculationDto(data);
  return data;
}
export function initCreateUpdateRecipeCalculationDto(_data: CreateUpdateRecipeCalculationDto) {
  if (_data) {
    if (Array.isArray(_data["recipe"])) {
      _data.recipe = _data["recipe"].map(item => 
        initIngredientForCalculationDto(item)
      );
    }
    _data.formulaViewType = _data["formulaViewType"];
  }
  return _data;
}
export function serializeCreateUpdateRecipeCalculationDto(_data: CreateUpdateRecipeCalculationDto | undefined) {
  if (_data) {
    _data = prepareSerializeCreateUpdateRecipeCalculationDto(_data as CreateUpdateRecipeCalculationDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeCreateUpdateRecipeCalculationDto(_data: CreateUpdateRecipeCalculationDto): CreateUpdateRecipeCalculationDto {
  const data: Record<string, any> = { ..._data };
  if (Array.isArray(_data.recipe)) {
    data["recipe"] = _data.recipe.map(item => 
        prepareSerializeIngredientForCalculationDto(item)
    );
  }
  return data as CreateUpdateRecipeCalculationDto;
}
export interface CalculationHistoryRecordDto  {
  /** Id of the Calculation in the system. */
  id: number;
  /** Name of the Calculation. */
  name: string;
  /** Recipe of the Calculation. */
  recipe: IngredientDto[];
}
export function deserializeCalculationHistoryRecordDto(json: string): CalculationHistoryRecordDto {
  const data = JSON.parse(json) as CalculationHistoryRecordDto;
  initCalculationHistoryRecordDto(data);
  return data;
}
export function initCalculationHistoryRecordDto(_data: CalculationHistoryRecordDto) {
  if (_data) {
    if (Array.isArray(_data["recipe"])) {
      _data.recipe = _data["recipe"].map(item => 
        initIngredientDto(item)
      );
    }
  }
  return _data;
}
export function serializeCalculationHistoryRecordDto(_data: CalculationHistoryRecordDto | undefined) {
  if (_data) {
    _data = prepareSerializeCalculationHistoryRecordDto(_data as CalculationHistoryRecordDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeCalculationHistoryRecordDto(_data: CalculationHistoryRecordDto): CalculationHistoryRecordDto {
  const data: Record<string, any> = { ..._data };
  if (Array.isArray(_data.recipe)) {
    data["recipe"] = _data.recipe.map(item => 
        prepareSerializeIngredientDto(item)
    );
  }
  return data as CalculationHistoryRecordDto;
}
export interface RecipeBatchDto  {
  /** Name of the Calculation. */
  recipeCalculationName: string;
  /** Sum of all ingredients batch values in the recipe. */
  overallBatchValuesSum: number;
  /** Calculated recipe calculation for batch. */
  recipe: BatchIngredientDto[];
}
export function deserializeRecipeBatchDto(json: string): RecipeBatchDto {
  const data = JSON.parse(json) as RecipeBatchDto;
  initRecipeBatchDto(data);
  return data;
}
export function initRecipeBatchDto(_data: RecipeBatchDto) {
  if (_data) {
    if (Array.isArray(_data["recipe"])) {
      _data.recipe = _data["recipe"].map(item => 
        initBatchIngredientDto(item)
      );
    }
  }
  return _data;
}
export function serializeRecipeBatchDto(_data: RecipeBatchDto | undefined) {
  if (_data) {
    _data = prepareSerializeRecipeBatchDto(_data as RecipeBatchDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeRecipeBatchDto(_data: RecipeBatchDto): RecipeBatchDto {
  const data: Record<string, any> = { ..._data };
  if (Array.isArray(_data.recipe)) {
    data["recipe"] = _data.recipe.map(item => 
        prepareSerializeBatchIngredientDto(item)
    );
  }
  return data as RecipeBatchDto;
}
export interface CalculateLinearBlend  {
  /** Name of the recipe calculation that should */
  namePrefix: string;
  /** Point A for blend calculation. */
  recipeCalculationAId: number;
  /** Point B for blend calculation. */
  recipeCalculationBId: number;
  /** Count of points in the blend. */
  pointsCount: number;
  /** Selected points to calculate. */
  selectedPoints: number[];
}
export function deserializeCalculateLinearBlend(json: string): CalculateLinearBlend {
  const data = JSON.parse(json) as CalculateLinearBlend;
  initCalculateLinearBlend(data);
  return data;
}
export function initCalculateLinearBlend(_data: CalculateLinearBlend) {
  if (_data) {
    _data.selectedPoints = _data["selectedPoints"];
  }
  return _data;
}
export function serializeCalculateLinearBlend(_data: CalculateLinearBlend | undefined) {
  if (_data) {
    _data = prepareSerializeCalculateLinearBlend(_data as CalculateLinearBlend);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeCalculateLinearBlend(_data: CalculateLinearBlend): CalculateLinearBlend {
  const data: Record<string, any> = { ..._data };
  return data as CalculateLinearBlend;
}
export interface CalculateTriangleBlendDto  {
  /** Name prefix of the recipe calculation that should created. */
  namePrefix: string;
  /** Point A for blend calculation. */
  recipeCalculationAId: number;
  /** Point B for blend calculation. */
  recipeCalculationBId: number;
  /** Point C for blend calculation. */
  recipeCalculationCId: number;
  /** Height of the triangle blend. */
  heightOfTriangle: number;
  /** Selected points to calculate. */
  selectedPoints: number[];
}
export function deserializeCalculateTriangleBlendDto(json: string): CalculateTriangleBlendDto {
  const data = JSON.parse(json) as CalculateTriangleBlendDto;
  initCalculateTriangleBlendDto(data);
  return data;
}
export function initCalculateTriangleBlendDto(_data: CalculateTriangleBlendDto) {
  if (_data) {
    _data.selectedPoints = _data["selectedPoints"];
  }
  return _data;
}
export function serializeCalculateTriangleBlendDto(_data: CalculateTriangleBlendDto | undefined) {
  if (_data) {
    _data = prepareSerializeCalculateTriangleBlendDto(_data as CalculateTriangleBlendDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeCalculateTriangleBlendDto(_data: CalculateTriangleBlendDto): CalculateTriangleBlendDto {
  const data: Record<string, any> = { ..._data };
  return data as CalculateTriangleBlendDto;
}
export interface CalculateRectangleBlendDto  {
  /** Name prefix of the recipe calculation that should created. */
  namePrefix: string;
  /** Point A for blend calculation. */
  recipeCalculationAId: number;
  /** Point B for blend calculation. */
  recipeCalculationBId: number;
  /** Point C for blend calculation. */
  recipeCalculationCId: number;
  /** Point D for blend calculation. */
  recipeCalculationDId: number;
  /** Height of the rectangle blend. */
  heightOfRectangle: number;
  /** Width of the rectangle blend. */
  widthOfRectangle: number;
  /** Selected points to calculate. */
  selectedPoints: number[];
}
export function deserializeCalculateRectangleBlendDto(json: string): CalculateRectangleBlendDto {
  const data = JSON.parse(json) as CalculateRectangleBlendDto;
  initCalculateRectangleBlendDto(data);
  return data;
}
export function initCalculateRectangleBlendDto(_data: CalculateRectangleBlendDto) {
  if (_data) {
    _data.selectedPoints = _data["selectedPoints"];
  }
  return _data;
}
export function serializeCalculateRectangleBlendDto(_data: CalculateRectangleBlendDto | undefined) {
  if (_data) {
    _data = prepareSerializeCalculateRectangleBlendDto(_data as CalculateRectangleBlendDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeCalculateRectangleBlendDto(_data: CalculateRectangleBlendDto): CalculateRectangleBlendDto {
  const data: Record<string, any> = { ..._data };
  return data as CalculateRectangleBlendDto;
}
export interface PromocodeDto  {
  /** Key word that user should enter to apply Promocode. */
  keyWord: string;
  /** Datetime, when Promocode expires. */
  expiresAt: Date;
  /** Count of users that can apply this Promocode. */
  limitCount: number | null;
  /** Amount of days that will be added to subscription. */
  subscriptionDaysToAdd: number;
  /** Datetime, when Promocode has been created. */
  createdAt: Date;
  /** Count of success applies of Promocode happened. */
  appliedCount: number;
  /** Subscription that will be applied, after Promocode used. */
  subscriptionLevelToApply: string;
  /** Shows if Promocode is expired. */
  isExpired: boolean;
}
export function deserializePromocodeDto(json: string): PromocodeDto {
  const data = JSON.parse(json) as PromocodeDto;
  initPromocodeDto(data);
  return data;
}
export function initPromocodeDto(_data: PromocodeDto) {
  if (_data) {
    _data.expiresAt = _data["expiresAt"] ? parseDateOnly(_data["expiresAt"].toString()) : <any>null;
    _data.createdAt = _data["createdAt"] ? new Date(_data["createdAt"].toString()) : <any>null;
  }
  return _data;
}
export function serializePromocodeDto(_data: PromocodeDto | undefined) {
  if (_data) {
    _data = prepareSerializePromocodeDto(_data as PromocodeDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializePromocodeDto(_data: PromocodeDto): PromocodeDto {
  const data: Record<string, any> = { ..._data };
  data["expiresAt"] = _data.expiresAt && formatDate(_data.expiresAt);
  data["createdAt"] = _data.createdAt && _data.createdAt.toISOString();
  return data as PromocodeDto;
}
export interface CreatePromocodeDto  {
  /** Key word that user should enter to apply Promocode. */
  keyWord: string;
  /** Datetime, when Promocode expires. */
  expiredAt: Date;
  /** Count of users that can apply this Promocode. */
  limitCount: number | null;
  /** Amount of days that will be added to subscription. */
  subscriptionDaysToAdd: number;
}
export function deserializeCreatePromocodeDto(json: string): CreatePromocodeDto {
  const data = JSON.parse(json) as CreatePromocodeDto;
  initCreatePromocodeDto(data);
  return data;
}
export function initCreatePromocodeDto(_data: CreatePromocodeDto) {
  if (_data) {
    _data.expiredAt = _data["expiredAt"] ? parseDateOnly(_data["expiredAt"].toString()) : <any>null;
  }
  return _data;
}
export function serializeCreatePromocodeDto(_data: CreatePromocodeDto | undefined) {
  if (_data) {
    _data = prepareSerializeCreatePromocodeDto(_data as CreatePromocodeDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeCreatePromocodeDto(_data: CreatePromocodeDto): CreatePromocodeDto {
  const data: Record<string, any> = { ..._data };
  data["expiredAt"] = _data.expiredAt && formatDate(_data.expiredAt);
  return data as CreatePromocodeDto;
}
/** Represents material's data. */
export interface MaterialDto extends MaterialBaseDto  {
  /** Description of the material. */
  description: string;
  /** Chemical formula of the material.
Stored as dictionary, where key is Oxide and value is percentage of oxide in the material. */
  formula: { [key in keyof typeof Oxide]?: number; };
  /** Id of the user to which the material belongs to. */
  userId: string;
}
export function deserializeMaterialDto(json: string): MaterialDto {
  const data = JSON.parse(json) as MaterialDto;
  initMaterialDto(data);
  return data;
}
export function initMaterialDto(_data: MaterialDto) {
  initMaterialBaseDto(_data);
    return _data;
}
export function serializeMaterialDto(_data: MaterialDto | undefined) {
  if (_data) {
    _data = prepareSerializeMaterialDto(_data as MaterialDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeMaterialDto(_data: MaterialDto): MaterialDto {
  const data = prepareSerializeMaterialBaseDto(_data as MaterialDto) as Record<string, any>;
  return data as MaterialDto;
}
/** Dto for new material creation. */
export interface CreateMaterialDto  {
  /** Name of the material. */
  name: string;
  /** Description of the material. */
  description?: string;
  /** Id of the material for Ovo's laboratory for automatic recipe mixers. */
  ovoId?: number | null;
  /** Chemical formula of the material.
Stored as dictionary, where key is Oxide and value is percentage of oxide in the material. */
  formula: { [key in keyof typeof Oxide]?: number; };
}
export function deserializeCreateMaterialDto(json: string): CreateMaterialDto {
  const data = JSON.parse(json) as CreateMaterialDto;
  initCreateMaterialDto(data);
  return data;
}
export function initCreateMaterialDto(_data: CreateMaterialDto) {
    return _data;
}
export function serializeCreateMaterialDto(_data: CreateMaterialDto | undefined) {
  if (_data) {
    _data = prepareSerializeCreateMaterialDto(_data as CreateMaterialDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeCreateMaterialDto(_data: CreateMaterialDto): CreateMaterialDto {
  const data: Record<string, any> = { ..._data };
  return data as CreateMaterialDto;
}
/** Dto for update of material data. */
export interface PatchMaterialDto  {
  /** Name of the material. */
  name?: string | null;
  /** Description of the material. */
  description?: string | null;
  /** Id of the material for Ovo's laboratory for automatic recipe mixers. */
  ovoId?: number | null;
  /** Chemical formula of the material.
Stored as dictionary, where key is Oxide and value is percentage of oxide in the material. */
  formula?: { [key in keyof typeof Oxide]?: number; };
}
export function deserializePatchMaterialDto(json: string): PatchMaterialDto {
  const data = JSON.parse(json) as PatchMaterialDto;
  initPatchMaterialDto(data);
  return data;
}
export function initPatchMaterialDto(_data: PatchMaterialDto) {
    return _data;
}
export function serializePatchMaterialDto(_data: PatchMaterialDto | undefined) {
  if (_data) {
    _data = prepareSerializePatchMaterialDto(_data as PatchMaterialDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializePatchMaterialDto(_data: PatchMaterialDto): PatchMaterialDto {
  const data: Record<string, any> = { ..._data };
  return data as PatchMaterialDto;
}
export interface CreateOrUpdateInvestigationDto  {
  /** Text of the Investigation. */
  text: string;
}
export function deserializeCreateOrUpdateInvestigationDto(json: string): CreateOrUpdateInvestigationDto {
  const data = JSON.parse(json) as CreateOrUpdateInvestigationDto;
  initCreateOrUpdateInvestigationDto(data);
  return data;
}
export function initCreateOrUpdateInvestigationDto(_data: CreateOrUpdateInvestigationDto) {
    return _data;
}
export function serializeCreateOrUpdateInvestigationDto(_data: CreateOrUpdateInvestigationDto | undefined) {
  if (_data) {
    _data = prepareSerializeCreateOrUpdateInvestigationDto(_data as CreateOrUpdateInvestigationDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeCreateOrUpdateInvestigationDto(_data: CreateOrUpdateInvestigationDto): CreateOrUpdateInvestigationDto {
  const data: Record<string, any> = { ..._data };
  return data as CreateOrUpdateInvestigationDto;
}
export interface CalculationTemplateDto  {
  /** Id of the recipe, that is source of the template. */
  recipeId: number;
  /** Name of the template. */
  name: string;
  /** Type of the template. */
  type: string;
  /** Recipe of the Template. */
  recipe: IngredientDto[];
  /** In case of true, additional ingredients would be included into calculation. */
  includeAdditionsIntoCalculations: boolean;
}
export function deserializeCalculationTemplateDto(json: string): CalculationTemplateDto {
  const data = JSON.parse(json) as CalculationTemplateDto;
  initCalculationTemplateDto(data);
  return data;
}
export function initCalculationTemplateDto(_data: CalculationTemplateDto) {
  if (_data) {
    if (Array.isArray(_data["recipe"])) {
      _data.recipe = _data["recipe"].map(item => 
        initIngredientDto(item)
      );
    }
  }
  return _data;
}
export function serializeCalculationTemplateDto(_data: CalculationTemplateDto | undefined) {
  if (_data) {
    _data = prepareSerializeCalculationTemplateDto(_data as CalculationTemplateDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeCalculationTemplateDto(_data: CalculationTemplateDto): CalculationTemplateDto {
  const data: Record<string, any> = { ..._data };
  if (Array.isArray(_data.recipe)) {
    data["recipe"] = _data.recipe.map(item => 
        prepareSerializeIngredientDto(item)
    );
  }
  return data as CalculationTemplateDto;
}
export interface TestPatchDto  {
  value: string;
}
export function deserializeTestPatchDto(json: string): TestPatchDto {
  const data = JSON.parse(json) as TestPatchDto;
  initTestPatchDto(data);
  return data;
}
export function initTestPatchDto(_data: TestPatchDto) {
    return _data;
}
export function serializeTestPatchDto(_data: TestPatchDto | undefined) {
  if (_data) {
    _data = prepareSerializeTestPatchDto(_data as TestPatchDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeTestPatchDto(_data: TestPatchDto): TestPatchDto {
  const data: Record<string, any> = { ..._data };
  return data as TestPatchDto;
}
export interface RegisterNewUserDto  {
  /** Last name of the user. */
  lastName: string;
  /** First name of the user. */
  firstName: string;
  /** Email of the user. */
  email: string;
  /** Password for the User. */
  password: string;
}
export function deserializeRegisterNewUserDto(json: string): RegisterNewUserDto {
  const data = JSON.parse(json) as RegisterNewUserDto;
  initRegisterNewUserDto(data);
  return data;
}
export function initRegisterNewUserDto(_data: RegisterNewUserDto) {
    return _data;
}
export function serializeRegisterNewUserDto(_data: RegisterNewUserDto | undefined) {
  if (_data) {
    _data = prepareSerializeRegisterNewUserDto(_data as RegisterNewUserDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeRegisterNewUserDto(_data: RegisterNewUserDto): RegisterNewUserDto {
  const data: Record<string, any> = { ..._data };
  return data as RegisterNewUserDto;
}
export interface ConfirmUserMailDto  {
  /** Email of the user for whom password will be changed. */
  userEmail: string;
  /** Token for password changing. */
  token: string;
}
export function deserializeConfirmUserMailDto(json: string): ConfirmUserMailDto {
  const data = JSON.parse(json) as ConfirmUserMailDto;
  initConfirmUserMailDto(data);
  return data;
}
export function initConfirmUserMailDto(_data: ConfirmUserMailDto) {
    return _data;
}
export function serializeConfirmUserMailDto(_data: ConfirmUserMailDto | undefined) {
  if (_data) {
    _data = prepareSerializeConfirmUserMailDto(_data as ConfirmUserMailDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeConfirmUserMailDto(_data: ConfirmUserMailDto): ConfirmUserMailDto {
  const data: Record<string, any> = { ..._data };
  return data as ConfirmUserMailDto;
}
export interface ResetPasswordDto  {
  /** Email of the user for whom password will be changed. */
  userEmail: string;
  /** Token for password changing. */
  token: string;
  /** New password for the User. */
  newPassword: string;
}
export function deserializeResetPasswordDto(json: string): ResetPasswordDto {
  const data = JSON.parse(json) as ResetPasswordDto;
  initResetPasswordDto(data);
  return data;
}
export function initResetPasswordDto(_data: ResetPasswordDto) {
    return _data;
}
export function serializeResetPasswordDto(_data: ResetPasswordDto | undefined) {
  if (_data) {
    _data = prepareSerializeResetPasswordDto(_data as ResetPasswordDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeResetPasswordDto(_data: ResetPasswordDto): ResetPasswordDto {
  const data: Record<string, any> = { ..._data };
  return data as ResetPasswordDto;
}
/** Contains user's subscription info. */
export interface UserInfoDto  {
  /** First name of the user. */
  firstName: string;
  /** Last name of the user. */
  lastName: string;
  /** Nickname of the user. */
  userName: string;
  /** Email of the user. */
  email: string;
  /** Roles of the user. */
  roles: string[] | null;
  /** Current subscription level of the user. */
  subscriptionLevel: string;
}
export function deserializeUserInfoDto(json: string): UserInfoDto {
  const data = JSON.parse(json) as UserInfoDto;
  initUserInfoDto(data);
  return data;
}
export function initUserInfoDto(_data: UserInfoDto) {
  if (_data) {
    _data.roles = _data["roles"];
  }
  return _data;
}
export function serializeUserInfoDto(_data: UserInfoDto | undefined) {
  if (_data) {
    _data = prepareSerializeUserInfoDto(_data as UserInfoDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeUserInfoDto(_data: UserInfoDto): UserInfoDto {
  const data: Record<string, any> = { ..._data };
  return data as UserInfoDto;
}
export interface UserSubscriptionInfoDto  {
  /** Current subscription level of the user. */
  subscriptionLevel: string;
  /** Datetime in UTC, when the subscription will be expired.
Null in case, when it's timeless. */
  subscriptionExpiresAt: Date | null;
  /** Maximum number of the photos, that the user allowed to upload according to theirs subscription. */
  maxPhotosCount: number | null;
  /** Count of the uploaded photos by the user. */
  uploadedPhotosCount: number;
  /** Maximum recipe calculators in one recipe. */
  maxRecipeCalculatorsPerRecipeCount: number | null;
  /** Shows whether user with such subscription is allowed to create/edit investigations. */
  additionalInvestigationFeaturesAccess: boolean;
}
export function deserializeUserSubscriptionInfoDto(json: string): UserSubscriptionInfoDto {
  const data = JSON.parse(json) as UserSubscriptionInfoDto;
  initUserSubscriptionInfoDto(data);
  return data;
}
export function initUserSubscriptionInfoDto(_data: UserSubscriptionInfoDto) {
  if (_data) {
    _data.subscriptionExpiresAt = _data["subscriptionExpiresAt"] ? new Date(_data["subscriptionExpiresAt"].toString()) : <any>null;
  }
  return _data;
}
export function serializeUserSubscriptionInfoDto(_data: UserSubscriptionInfoDto | undefined) {
  if (_data) {
    _data = prepareSerializeUserSubscriptionInfoDto(_data as UserSubscriptionInfoDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeUserSubscriptionInfoDto(_data: UserSubscriptionInfoDto): UserSubscriptionInfoDto {
  const data: Record<string, any> = { ..._data };
  data["subscriptionExpiresAt"] = _data.subscriptionExpiresAt && _data.subscriptionExpiresAt.toISOString();
  return data as UserSubscriptionInfoDto;
}
/** Dto to update User name. */
export interface UpdateUserNameDto  {
  /** First name of the User. */
  firstName: string;
  /** Last name of the User. */
  lastName: string;
}
export function deserializeUpdateUserNameDto(json: string): UpdateUserNameDto {
  const data = JSON.parse(json) as UpdateUserNameDto;
  initUpdateUserNameDto(data);
  return data;
}
export function initUpdateUserNameDto(_data: UpdateUserNameDto) {
    return _data;
}
export function serializeUpdateUserNameDto(_data: UpdateUserNameDto | undefined) {
  if (_data) {
    _data = prepareSerializeUpdateUserNameDto(_data as UpdateUserNameDto);
  }
  return JSON.stringify(_data);
}
export function prepareSerializeUpdateUserNameDto(_data: UpdateUserNameDto): UpdateUserNameDto {
  const data: Record<string, any> = { ..._data };
  return data as UpdateUserNameDto;
}
export function formatDate(d: Date) {
    return d.getFullYear() + '-' + 
        (d.getMonth() < 9 ? ('0' + (d.getMonth()+1)) : (d.getMonth()+1)) + '-' +
        (d.getDate() < 10 ? ('0' + d.getDate()) : d.getDate());
}
export function parseDateOnly(s: string) {
    const date = new Date(s);
    return new Date(date.getTime() + 
        date.getTimezoneOffset() * 60000);
}
import type { AxiosError } from 'axios'
export interface FileParameter {
    data: any;
    fileName: string;
}
export interface FileResponse {
    data: Blob;
    status: number;
    fileName?: string;
    headers?: { [name: string]: any };
}
export class ApiException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;
    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();
        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }
    protected isApiException = true;
    static isApiException(obj: any): obj is ApiException {
        return obj.isApiException === true;
    }
}
export function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new ApiException(message, status, response, headers, null);
}
export function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}
//-----/Types.File-----