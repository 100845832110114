import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';
import { SignUrlQuery } from '@/services/api/api-client';
import { FC, lazy } from 'react';

const RootPageDefaultView = lazy(() => import('./default-view/RootPageDefaultView'));

const RootPageMobileView = lazy(() => import('./mobile-view/RootPageMobileView'));

export const RootPage: FC = () => {
  const isMobile = useMobileMediaQuery();

  const cookieQuery = SignUrlQuery.useSetSignatureCookieQuery({
    refetchIntervalInBackground: true,
    refetchInterval: 10 * 60 * 1000 /* once in 10 minutes */,
  });

  if (cookieQuery.isLoading) {
    return null;
  }

  return isMobile ? <RootPageMobileView /> : <RootPageDefaultView />;
};
