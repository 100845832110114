import FanreekkImage from '@/assets/img/fanreekk.svg?react';
import { Button } from '@/components/uikit/Button/Button';
import { Dialog } from '@/components/uikit/Dialog/Dialog';
import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PreventCloseDialog.module.scss';

type Props = {
  isOpened: boolean;
  onClose: () => void;
  onGoAway: () => void;
};

export const PreventCloseDialog: FC<Props> = ({ isOpened, onClose, onGoAway }) => {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth={false} maxWidth={'md'} open={isOpened} onClose={onClose}>
      <Stack direction={'row'}>
        <FanreekkImage className={styles.fanreekkImage} />
        <div>
          <DialogTitle sx={{ fontWeight: 600, fontSize: '16px' }}>
            {t('PreventCloseDialog.Title')}
          </DialogTitle>
          <DialogContent
            sx={{ fontWeight: 400, fontSize: '16px', marginTop: '30px', whiteSpace: 'pre-wrap' }}
          >
            {t('PreventCloseDialog.Message')}
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', margin: '30px 10px 0 10px' }}>
            <Button textColor={'#D7510D'} onClick={onGoAway}>
              {t('PreventCloseDialog.GoAnyway')}
            </Button>
            <Button onClick={onClose}>{t('PreventCloseDialog.Stay')}</Button>
          </DialogActions>
        </div>
      </Stack>
    </Dialog>
  );
};
