import constate from 'constate';
import { useMemo } from 'react';
import { useParams } from 'react-router';

type RecipeParamProps = {
  recipeId?: number;
};

export const [RecipeParamProvider, useRecipeParam] = constate(
  ({ recipeId: recipeIdFromProps }: RecipeParamProps) => {
    const { recipeId: recipeIdFromParams } = useParams();

    if (!recipeIdFromParams && !recipeIdFromProps) {
      throw new Error(
        "Param 'recipeId' was not present in params, most likely it is a different route.",
      );
    }

    return useMemo(
      () => ({
        recipeId: recipeIdFromProps ? recipeIdFromProps : Number(recipeIdFromParams),
      }),
      [recipeIdFromParams, recipeIdFromProps],
    );
  },
);
