import CloseIcon from '@/assets/icons/white-cross.svg?react';
import IconButton from '@mui/material/IconButton';
import { SnackbarKey, useSnackbar } from 'notistack';
import { FC, useCallback } from 'react';

type Props = {
  snackbarKey: SnackbarKey;
};

export const CloseSnackbarButton: FC<Props> = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  const handleClose = useCallback(() => closeSnackbar(snackbarKey), [closeSnackbar, snackbarKey]);

  return (
    <IconButton onClick={handleClose}>
      <CloseIcon />
    </IconButton>
  );
};
