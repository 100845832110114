import { RecipeCalculationQuery } from '@/services/api/api-client';
import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents';
import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from '@lexical/react/LexicalDecoratorBlockNode';
import type {
  EditorConfig,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  Spread,
} from 'lexical';

import { RecipeCalculator } from '../../../../../../../pages/authorized/recipe-calculations-page/default-view/recipe-calculator/RecipeCalculator';
import { useRecipeCalculations } from '../../../../../../hooks/recipe/useRecipeCalculations';

type CalculationComponentProps = Readonly<{
  className: Readonly<{
    base: string;
    focus: string;
  }>;
  format: ElementFormatType | null;
  nodeKey: NodeKey;
  recipeId: number;
  calculationId: number;
  versionId: number;
}>;

const CalculationComponent = ({
  className,
  format,
  nodeKey,
  recipeId,
  calculationId,
  versionId,
}: CalculationComponentProps) => {
  const versionQuery = RecipeCalculationQuery.useGetVersionQuery(
    recipeId,
    calculationId,
    versionId,
  );

  const { calculations, removedCalculations } = useRecipeCalculations();
  const calculation =
    calculations.find((x) => x.id === calculationId) ??
    removedCalculations.find((x) => x.id === calculationId);

  return (
    <BlockWithAlignableContents className={className} nodeKey={nodeKey}>
      {calculation && versionQuery.data && (
        <RecipeCalculator
          showStullChart
          recipeId={recipeId}
          calculation={calculation}
          history={versionQuery.data}
          isReadonly={true}
          isSelectable={false}
          format={format}
        />
      )}
    </BlockWithAlignableContents>
  );
};

export type SerializedCalculationNode = Spread<
  {
    recipeId: number;
    calculationId: number;
    versionId: number;
    type: 'calculation';
    version: 1;
  },
  SerializedDecoratorBlockNode
>;

export class CalculationNode extends DecoratorBlockNode {
  __recipeId: number;
  __calculationId: number;
  __versionId: number;

  static getType(): string {
    return 'calculation';
  }

  static clone(node: CalculationNode): CalculationNode {
    return new CalculationNode(
      node.__recipeId,
      node.__calculationId,
      node.__versionId,
      node.__format,
      node.__key,
    );
  }

  static importJSON(serializedNode: SerializedCalculationNode): CalculationNode {
    const node = $createCalculationNode(
      serializedNode.recipeId,
      serializedNode.calculationId,
      serializedNode.versionId,
    );
    node.setFormat(serializedNode.format);
    return node;
  }

  exportJSON(): SerializedCalculationNode {
    return {
      ...super.exportJSON(),
      type: 'calculation',
      version: 1,
      recipeId: this.__recipeId,
      calculationId: this.__calculationId,
      versionId: this.__versionId,
    };
  }

  constructor(
    recipeId: number,
    calculationId: number,
    versionId: number,
    format?: ElementFormatType,
    key?: NodeKey,
  ) {
    super(format, key);
    this.__recipeId = recipeId;
    this.__calculationId = calculationId;
    this.__versionId = versionId;
  }

  updateDOM(): false {
    return false;
  }

  getId(): string {
    return this.__id;
  }

  decorate(_editor: LexicalEditor, config: EditorConfig): JSX.Element {
    const embedBlockTheme = config.theme.embedBlock || {};
    const className = {
      base: embedBlockTheme.base || '',
      focus: embedBlockTheme.focus || '',
    };
    return (
      <CalculationComponent
        className={className}
        format={this.__format}
        nodeKey={this.getKey()}
        recipeId={this.__recipeId}
        calculationId={this.__calculationId}
        versionId={this.__versionId}
      />
    );
  }

  isInline(): false {
    return false;
  }
}

export function $createCalculationNode(
  recipeId: number,
  calculationId: number,
  versionId: number,
): CalculationNode {
  return new CalculationNode(recipeId, calculationId, versionId);
}

export function $isCalculationNode(
  node: CalculationNode | LexicalNode | null | undefined,
): node is CalculationNode {
  return node instanceof CalculationNode;
}
