import { useInvestigation } from '../../../../hooks/recipe/useInvestigation';

import { InvestigationForm } from './InvestigationForm';

export const InvestigationPage = () => {
  const { investigation } = useInvestigation();

  if (!investigation) {
    return null;
  }

  return (
    <InvestigationForm initialText={investigation.text} isReadonly={investigation.isReadonly} />
  );
};
