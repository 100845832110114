import { Button } from '@/components/uikit/Button/Button';
import { TextField } from '@/components/uikit/TextField/TextField';
import { requiredRule } from '@/helpers/form/react-hook-form-helper';
import { useAdvancedForm } from '@/helpers/form/useAdvancedForm';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { ValidateResult } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './AddTableDialog.module.scss';

export type AddTableForm = {
  columnsCount: number;
  rowsCount: number;
};

type OwnProps = {
  onSubmit: (columnsCount: number, rowsCount: number) => void;
  onClose: () => void;
};

const MIN_COLUMNS_OR_ROW_COUNT = 1;
const MAX_COLUMNS_OR_ROW_COUNT = 100;

export const AddTableDialog: FC<OwnProps> = ({ onSubmit, onClose }) => {
  const { t } = useTranslation();

  const form = useAdvancedForm<AddTableForm>(
    useCallback(async (data) => {
      onSubmit(data.columnsCount, data.rowsCount);
      onClose();
    }, []),
  );

  const handleValidate = useCallback((value: number): ValidateResult => {
    if (value < MIN_COLUMNS_OR_ROW_COUNT || value > MAX_COLUMNS_OR_ROW_COUNT) {
      return t('InvestigationEditor.Tables.Add.InvalidRowColumnCount');
    }
    return true;
  }, []);

  return (
    <>
      <DialogTitle>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'left'} spacing={1}>
          <BackupTableIcon color={'primary'} />
          <div className={styles.addTableTitle}>{t('InvestigationEditor.Tables.Add.Table')}</div>
        </Stack>
      </DialogTitle>
      <form onSubmit={form.handleSubmitDefault} onReset={onClose}>
        <DialogContent>
          <Stack direction={'row'} spacing={3}>
            <TextField
              {...form.register('columnsCount', { ...requiredRule(), validate: handleValidate })}
              type={'number'}
              fullWidth
              placeholder={t('InvestigationEditor.Tables.Add.ColumnsCount')}
              error={!!form.formState.errors.columnsCount?.message}
              helperText={t(form.formState.errors.columnsCount?.message ?? ' ')}
              inputProps={{ min: 0, max: 100, step: 'any' }}
            />
            <TextField
              {...form.register('rowsCount', { ...requiredRule(), validate: handleValidate })}
              type={'number'}
              fullWidth
              placeholder={t('InvestigationEditor.Tables.Add.RowsCount')}
              error={!!form.formState.errors.rowsCount?.message}
              helperText={t(form.formState.errors.rowsCount?.message ?? ' ')}
              inputProps={{ min: 0, max: 100, step: 'any' }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', margin: '0 15px 0 15px' }}>
          <Button textColor={'#000'} type={'reset'} onClick={onClose}>
            {t('UIKit.Cancel')}
          </Button>
          <Button onClick={form.handleSubmitDefault}>{t('UIKit.Apply')}</Button>
        </DialogActions>
      </form>
    </>
  );
};
