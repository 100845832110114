import { Button as MuiButton } from '@mui/material';
import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';

import styles from './Button.module.scss';

type Props = ComponentProps<typeof MuiButton> & {
  textColor?: string;
};

export const Button = forwardRef<HTMLButtonElement, Props>(({ textColor, ...props }, ref) => (
  <MuiButton
    {...props}
    ref={ref}
    sx={{ ...props.sx, color: textColor }}
    className={clsx(styles.button, props.className)}
  />
));
