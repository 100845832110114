import {
  InvestigationDto,
  InvestigationQuery,
  RecipeCalculationDto,
  RecipeCalculationQuery,
  RecipeDto,
  RecipeInfoDto,
  RecipePhotoDto,
  RecipeQuery,
} from '@/services/api/api-client';
import { QueryClient } from '@tanstack/react-query';

export const updateExistingRecipe = (
  queryClient: QueryClient,
  recipeId: number,
  updatedRecipe: RecipeInfoDto,
) => {
  queryClient.setQueryData(RecipeQuery.getQueryKey(recipeId), (oldRecipe?: RecipeDto) => {
    if (!oldRecipe) {
      throw new Error('Recipe was expected to be present.');
    }
    return {
      ...oldRecipe,
      ...updatedRecipe,
    };
  });
};

export const addNewRecipeCalculation = (
  queryClient: QueryClient,
  recipeId: number,
  createdCalculation: RecipeCalculationDto,
) => {
  queryClient.setQueryData(
    RecipeCalculationQuery.getAllQueryKey(recipeId),
    (oldCalculations?: RecipeCalculationDto[]) => {
      if (!oldCalculations) {
        throw new Error('Recipe calculations were expected be present.');
      }
      return [...oldCalculations, createdCalculation];
    },
  );
};

export const removeExistingRecipeCalculation = (
  queryClient: QueryClient,
  recipeId: number,
  removedCalculationId: number,
) => {
  queryClient.setQueryData(
    RecipeCalculationQuery.getAllQueryKey(recipeId),
    (oldCalculations?: RecipeCalculationDto[]) => {
      if (!oldCalculations) {
        throw new Error('Recipe calculations were expected be present.');
      }
      return oldCalculations?.filter((x) => x.id !== removedCalculationId);
    },
  );
};

export const updateExistingRecipeCalculation = (
  queryClient: QueryClient,
  recipeId: number,
  updatedRecipeCalculation: RecipeCalculationDto,
) => {
  queryClient.setQueryData(
    RecipeCalculationQuery.getAllQueryKey(recipeId),
    (oldCalculations?: RecipeCalculationDto[]) => {
      if (!oldCalculations) {
        throw new Error('Recipe calculations were expected be present.');
      }

      const oldRecipeCalculationIndex = oldCalculations.findIndex(
        (r) => r.id === updatedRecipeCalculation.id,
      );
      if (oldRecipeCalculationIndex === -1) {
        throw new Error('Could not find recipe calculation for updating.');
      }

      const recipeCalculations = [...oldCalculations];
      recipeCalculations[oldRecipeCalculationIndex] = updatedRecipeCalculation;

      return recipeCalculations;
    },
  );
};

export const addPublishedStateToRecipe = (
  queryClient: QueryClient,
  recipeId: number,
  published: boolean,
) => {
  queryClient.setQueryData(RecipeQuery.getQueryKey(recipeId), (oldRecipe?: RecipeDto) => {
    if (!oldRecipe) {
      throw new Error('Recipe was expected to have values.');
    }
    return {
      ...oldRecipe,
      published: published,
    };
  });
};

export const addRecipePhotoToExistingRecipe = (
  queryClient: QueryClient,
  recipeId: number,
  addedPhoto: RecipePhotoDto,
) => {
  queryClient.setQueryData(RecipeQuery.getQueryKey(recipeId), (oldRecipe?: RecipeDto) => {
    if (!oldRecipe) {
      throw new Error('Recipe was expected to have values.');
    }
    return {
      ...oldRecipe,
      photos: oldRecipe.photos ? [...oldRecipe.photos, addedPhoto] : [addedPhoto],
    };
  });
};

export const updateRecipePhotoInExistingRecipe = (
  queryClient: QueryClient,
  recipeId: number,
  photoId: string,
  updatedDescription: string,
) => {
  queryClient.setQueryData(RecipeQuery.getQueryKey(recipeId), (oldRecipe?: RecipeDto) => {
    if (!oldRecipe?.photos) {
      throw new Error('Recipe was expected to have values.');
    }

    const existingPhotoIndex = oldRecipe.photos.findIndex((r) => r.id === photoId);
    if (existingPhotoIndex === -1) {
      throw new Error('Could not find recipe photo for updating.');
    }

    const photos = [...oldRecipe.photos];
    photos[existingPhotoIndex].description = updatedDescription;
    return {
      ...oldRecipe,
      photos,
    };
  });
};

export const removeRecipePhotoFromExistingRecipe = (
  queryClient: QueryClient,
  recipeId: number,
  photoId: string,
) => {
  queryClient.setQueryData(RecipeQuery.getQueryKey(recipeId), (oldRecipe?: RecipeDto) => {
    if (!oldRecipe?.photos) {
      throw new Error('Recipe was expected to have values.');
    }

    const removedPhotoIndex = oldRecipe.photos.findIndex((r) => r.id === photoId);
    if (removedPhotoIndex === -1) {
      throw new Error('Could not find recipe photo for removing.');
    }

    const photos = [...oldRecipe.photos];
    photos.splice(removedPhotoIndex, 1);
    return {
      ...oldRecipe,
      photos,
    };
  });
};

export const updateExistingInvestigation = (
  queryClient: QueryClient,
  recipeId: number,
  updatedInvestigation: InvestigationDto,
) => {
  queryClient.setQueryData(
    InvestigationQuery.getQueryKey(recipeId),
    (oldInvestigation?: InvestigationDto) => {
      if (!oldInvestigation) {
        throw new Error('Recipe was expected to have values.');
      }
      return {
        ...oldInvestigation,
        ...updatedInvestigation,
      } as InvestigationDto;
    },
  );
};
