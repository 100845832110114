import Highcharts, { SeriesOptionsType } from 'highcharts';
import i18next from 'i18next';

import { StullChartPoint } from './StullChart';

// Define a custom symbol path
// See https://stackoverflow.com/questions/27542928/extend-highcharts-renderer-symbols-to-have-plus-sign
// @ts-ignore
Highcharts.SVGRenderer.prototype.symbols.customTriangle = function (
  x: any,
  y: any,
  w: any,
  h: any,
) {
  return ['M', x + w / 2, y, 'L', x, y + h, 'L', x + w, y + h, 'L', x + w / 2, y, 'z'];
};
// @ts-ignore
Highcharts.SVGRenderer.prototype.symbols.customTriangle90 = function (
  x: any,
  y: any,
  w: any,
  h: any,
) {
  return ['M', x, y, 'L', x + w, y + h / 2, 'L', x, y + h, 'L', x, y, 'z'];
};
// @ts-ignore
Highcharts.SVGRenderer.prototype.symbols.customTriangle180 = function (
  x: any,
  y: any,
  w: any,
  h: any,
) {
  return ['M', x, y, 'L', x + w / 2, y + h, 'L', x + w, y, 'L', x, y, 'z'];
};
// @ts-ignore
Highcharts.SVGRenderer.prototype.symbols.customTriangle270 = function (
  x: any,
  y: any,
  w: any,
  h: any,
) {
  return ['M', x + w, y, 'L', x, y + h / 2, 'L', x + w, y + h, 'L', x + w, y, 'z'];
};

// Maximum and minimum values of Al2O3 and SiO2 that can be displayed on the Stull chart.
const getMaxSiO2Value = (minimized?: boolean) => (minimized ? 6.6 : 7.2);
const minSiO2Value = 0.6;
const getMaxAl2O3Value = (minimized?: boolean) => (minimized ? 0.9 : 1);
const minAl2O3Value = 0;

// Dummy points to render points that are outside the Stull chart.
const maxX = (minimized?: boolean) => (minimized ? 6.5 : 7.1);
const minX = 0.7;
const maxY = 0.98;
const minY = 0.017;

export const getChartOptions = (
  stullChartPoints: StullChartPoint[],
  selectedRecipeCalculationId: number | undefined,
  showMinimized?: boolean,
): Highcharts.Options => {
  return {
    chart: {
      borderWidth: 0,
      borderRadius: 0,
      backgroundColor: '#1C74EA',
      //zoomType: 'xy',
      height: showMinimized ? '100%' : '80%',
      spacing: [0, 0, 0, 0],
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      scatter: {
        turboThreshold: 5000,
        marker: {
          enabled: true,
          radius: 4,
          symbol: 'circle',
        },
        color: 'rgba(0, 0, 0, 0.5)',
        cursor: showMinimized ? 'unset' : 'pointer',
        animation: false,
      },
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      enabled: !showMinimized,
    },
    xAxis: {
      gridLineWidth: showMinimized ? 0 : 1,
      gridLineColor: 'rgba(157, 202, 255, 0.2)',
      title: {
        text: '',
      },
      zoomEnabled: false,
      startOnTick: true,
      endOnTick: true,
      showFirstLabel: false,
      showLastLabel: true,
      tickLength: 0,
      tickInterval: 0.6,
      labels: {
        enabled: !showMinimized,
        style: {
          color: 'rgba(255, 255, 255, 0.7)',
        },
        rotation: 0,
        format: '{value:.1f}',
        y: -5,
        zIndex: 1,
      },
      min: minSiO2Value,
      max: getMaxSiO2Value(showMinimized),
    },
    yAxis: {
      tickInterval: 0.1,
      gridLineWidth: showMinimized ? 0 : 1,
      gridLineColor: 'rgba(157, 202, 255, 0.2)',
      title: {
        text: '',
      },
      zoomEnabled: false,
      startOnTick: true,
      endOnTick: true,
      showLastLabel: false,
      showFirstLabel: false,
      labels: {
        enabled: !showMinimized,
        style: {
          color: '#fff',
        },
        format: '{value:.1f}',
        x: 25,
        zIndex: 1,
      },
      min: minAl2O3Value,
      max: getMaxAl2O3Value(showMinimized),
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: 'Unfused',
        type: 'polygon',
        animation: false,
        data: [
          [0.6, 0.42],
          [0.6, 1.0],
          [2.8, 1.0],
        ],
        color: 'rgba(0, 39, 175, 0.3)',
        lineWidth: 0,
        zIndex: 1,
        enableMouseTracking: false,
      },
      {
        name: 'Mattes',
        type: 'polygon',
        animation: false,
        data: [
          [2.8, 1.0],
          [4, 1.0],
          [0.6, 0.08],
          [0.6, 0.42],
        ],
        color: 'rgba(203, 224, 255, 0.2)',
        lineWidth: 0,
        zIndex: 1,
        enableMouseTracking: false,
      },
      {
        name: 'Semi-Mattes',
        type: 'polygon',
        animation: false,
        data: [
          [1.2, 0.242],
          [4, 1.0],
          [5, 1.0],
        ],
        color: 'rgba(255, 255, 255, 0.2)',
        lineWidth: 0,
        zIndex: 1,
        enableMouseTracking: false,
      },
      {
        name: 'Underfired',
        type: 'polygon',
        animation: false,
        data: [
          [1.6, 0.0],
          [7.2, 0.65],
          [7.2, 0.0],
        ],
        color: 'rgba(0, 39, 175, 0.3)',
        lineWidth: 0,
        zIndex: 1,
        enableMouseTracking: false,
      },
      {
        name: 'Crazing',
        type: 'areaspline',
        data: [
          [0.6, 0.09],
          [0.6, 1],
          [1.6, 1],
          [2.4, 0.25],
          [2.8, 0.23],
          [3.3, 0.25],
          [4.19, 0.299],
          [4.2, 0.3],
          [5.4, 0.49],
          [7.2, 0.62],
          [7.2, 0.09],
        ],
        marker: {
          enabled: false,
        },
        animation: false,
        color: 'rgba(0, 0, 0, 0.2)',
        fillOpacity: 1,
        lineWidth: 0,
        states: {
          hover: {
            enabled: false,
          },
        },
        enableMouseTracking: false,
        zIndex: 2,
      },
      ...stullChartPoints.map((point) =>
        getPointChartSeries(point, selectedRecipeCalculationId, showMinimized),
      ),
    ],
  };
};

const getPointChartSeries = (
  point: StullChartPoint,
  selectedRecipeCalculationId: number | undefined,
  showMinimized?: boolean,
): SeriesOptionsType => {
  return {
    id: `point_${point.recipeCalculationId}`,
    type: 'scatter',
    color: '#fff',
    dataLabels: {
      enabled: false,
      y: 18,
      allowOverlap: false,
      padding: 0,
      style: {
        color: 'rgba(0, 0, 0, 0.4)',
        fontWeight: 'bold',
        fontSize: '10px',
      },
    },
    allowPointSelect: !showMinimized,
    marker: {
      symbol: getSelectedSymbol(point, showMinimized),
      radius: showMinimized ? 3 : 5,
      lineColor: '#FFB422',
      lineWidth:
        selectedRecipeCalculationId && selectedRecipeCalculationId === point.recipeCalculationId
          ? 2
          : 0,
    },
    stickyTracking: false,
    zIndex: 3,
    data: [point].map((selectedPoint) => {
      return {
        x:
          selectedPoint && selectedPoint.siO2Value > getMaxSiO2Value(showMinimized)
            ? maxX(showMinimized)
            : selectedPoint && selectedPoint.siO2Value <= minSiO2Value
              ? minX
              : selectedPoint?.siO2Value,
        y:
          selectedPoint && selectedPoint.al2O3Value > getMaxAl2O3Value(showMinimized)
            ? maxY
            : selectedPoint && selectedPoint.al2O3Value <= minAl2O3Value
              ? minY
              : selectedPoint?.al2O3Value,
        realX: selectedPoint?.siO2Value,
        realY: selectedPoint?.al2O3Value,
        name: selectedPoint?.recipeCalculationName,
      };
    }),
    tooltip: {
      headerFormat: '',
      pointFormat:
        '<b>{point.name}</b><br/>{point.realX} ' +
        i18next.t('Oxide.SiO2') +
        ', {point.realY} ' +
        i18next.t('Oxide.Al2O3'),
    },
    states: {
      hover: {
        enabled: !showMinimized,
      },
    },
  };
};

const getSelectedSymbol = (selectedPoint: StullChartPoint, showMinimized?: boolean): string => {
  if (selectedPoint && selectedPoint.al2O3Value > getMaxAl2O3Value(showMinimized)) {
    return 'customTriangle';
  }
  if (selectedPoint && selectedPoint.siO2Value > getMaxSiO2Value(showMinimized)) {
    return 'customTriangle90';
  }
  if (selectedPoint && selectedPoint.siO2Value <= minSiO2Value) {
    return 'customTriangle270';
  }
  if (selectedPoint && selectedPoint.al2O3Value <= minAl2O3Value) {
    return 'customTriangle180';
  }
  return 'circle';
};
