import useCtrlS from '@/helpers/ctrlSHook';
import { useAdvancedForm } from '@/helpers/form/useAdvancedForm';
import { useBlocker } from '@/helpers/hooks/useBlocker';
import { PreventCloseDialog } from '@pages/authorized/recipe-page/common/recipe-header/prevent-close-dialog/PreventCloseDialog';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { useInvestigation } from '../../../../hooks/recipe/useInvestigation';

import { InvestigationEditor } from './InvestigationEditor';
import styles from './InvestigationForm.module.scss';

type Props = {
  initialText: string | null;
  isReadonly: boolean;
};

type FormData = {
  text: string | null;
};

export const InvestigationForm: FC<Props> = ({ initialText, isReadonly }) => {
  const [defaultText, setDefaultText] = useState(initialText);

  const { createOrUpdateInvestigation, isCreateOrUpdateInvestigationLoading: isLoading } =
    useInvestigation();

  const investigationForm = useAdvancedForm<FormData>(
    useCallback(
      async (data) => {
        if (data.text) {
          createOrUpdateInvestigation(data.text, {
            onSuccess: () => setDefaultText(data.text),
          });
        }
      },
      [createOrUpdateInvestigation],
    ),
    {
      mode: 'all',
      defaultValues: { text: initialText },
    },
  );

  useEffect(() => {
    investigationForm.reset({ text: defaultText });
  }, [defaultText]);

  const { blocked, keepHere, proceedForward } = useBlocker(investigationForm.formState.isDirty);

  const saveIsDisabled = !investigationForm.formState.isDirty || isLoading;

  useCtrlS(() => {
    if (!isReadonly && !saveIsDisabled) {
      investigationForm.handleSubmitDefault();
    }
  });

  return (
    <form className={styles.investigationForm} onSubmit={investigationForm.handleSubmitDefault}>
      <Controller
        name={'text'}
        control={investigationForm.control}
        render={({ field }) => {
          return (
            <InvestigationEditor
              onSave={investigationForm.handleSubmitDefault}
              value={field.value}
              onChange={field.onChange}
              saveIsDisabled={saveIsDisabled}
              isReadonly={isReadonly}
              isPrintViewEnabled={false}
            />
          );
        }}
      />
      <PreventCloseDialog isOpened={blocked} onClose={keepHere} onGoAway={proceedForward} />
    </form>
  );
};
