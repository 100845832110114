import { localFormat } from '@/helpers/date-helpers';
import { PromocodesQuery } from '@/services/api/api-client';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const PromocodesTable: FC = () => {
  const { t } = useTranslation();
  const allPromocodes = PromocodesQuery.useGetAllQuery();

  if (!allPromocodes.data) {
    return null;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell align='center'>{t('Promocodes.Keyword')}</TableCell>
            <TableCell align='center'>{t('Promocodes.SubscriptionLevelToApply')}</TableCell>
            <TableCell align='center'>{t('Promocodes.AddDaysOfSubscription')}</TableCell>
            <TableCell align='center'>{t('Promocodes.LimitCount')}</TableCell>
            <TableCell align='center'>{t('Promocodes.AppliedCount')}</TableCell>
            <TableCell align='center'>{t('Promocodes.CreatedAt')}</TableCell>
            <TableCell align='center'>{t('Promocodes.ExpiredAt')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allPromocodes.data.map((row) => (
            <TableRow
              key={row.keyWord}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: row.isExpired ? '#FFDBE7FF' : '#fff',
              }}
            >
              <TableCell component='th' scope='row' align='center'>
                {row.keyWord}
              </TableCell>
              <TableCell align='center'>{row.subscriptionLevelToApply}</TableCell>
              <TableCell align='center'>{row.subscriptionDaysToAdd}</TableCell>
              <TableCell align='center'>{row.limitCount ?? t('Promocodes.Any')}</TableCell>
              <TableCell align='center'>{row.appliedCount}</TableCell>
              <TableCell align='center'>{localFormat(row.createdAt, 'Pp')}</TableCell>
              <TableCell align='center'>{localFormat(row.expiresAt, 'Pp')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
