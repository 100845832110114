import { ToggleButtonGroup as MuiToggleButtonGroup } from '@mui/material';
import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';

import styles from './ToggleButtonGroup.module.scss';

type Props = ComponentProps<typeof MuiToggleButtonGroup> & {
  variant?: 'text' | 'outlined';
};

export const ToggleButtonGroup = forwardRef<unknown, Props>(
  ({ variant = 'outlined', ...props }, ref) => (
    <MuiToggleButtonGroup
      {...props}
      ref={ref}
      className={clsx(styles.group, styles[variant], props.className)}
    />
  ),
);
