import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';
import { FC, lazy } from 'react';

const HomePageDefaultView = lazy(() => import('./default-view/HomePageDefaultView'));

const HomePageMobileView = lazy(() => import('./mobile-view/HomePageMobileView'));

export const HomePage: FC = () => {
  const isMobile = useMobileMediaQuery();
  return isMobile ? <HomePageMobileView /> : <HomePageDefaultView />;
};
