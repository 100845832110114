import { IngredientDto, IngredientForCalculationDto } from '@/services/api/api-client.types';
import React from 'react';

import { IngredientFormField } from './RecipeCalculator';

export const emptyIngredientField: IngredientFormField = { material: undefined, value: '' };

export const buildIngredientDtos = (
  ingredientFields: IngredientFormField[],
  isAddition: boolean,
): IngredientForCalculationDto[] => {
  return ingredientFields
    .filter((x) => x.material && x.value)
    .map((m) => ({
      isAddition,
      value: parseFloat(m.value!),
      materialId: m.material!.id,
    }));
};

export const buildIngredientFields = (
  dtos: IngredientDto[] | undefined,
  isAddition: boolean,
): IngredientFormField[] =>
  dtos
    ?.filter((x) => x.isAddition === isAddition)
    .map((ingredient) => {
      return {
        value: ingredient.value.toString(),
        material: ingredient.material,
      };
    }) ?? [];

// Prevents form submit on pressed Enter
// See https://github.com/orgs/react-hook-form/discussions/2549#discussioncomment-5250806
export const preventEnterKeySubmission = (
  e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLFormElement>,
) => {
  const target = e.target as HTMLInputElement | HTMLTextAreaElement;
  if (e.key === 'Enter' && !['TEXTAREA'].includes(target.tagName)) {
    e.preventDefault();
  }
};
