import { Links } from '@/application/constants/links';
import AddIcon from '@/assets/icons/plus-circle.svg?react';
import { Dialog } from '@/components/uikit/Dialog/Dialog';
import { IconButton } from '@/components/uikit/IconButton/IconButton';
import { UserRoles } from '@/helpers/interceptors/auth/user-roles';
import { UserQuery } from '@/services/api/api-client';
import { Stack } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';

import { CreatePromocodeForm } from './create-promocode-form/CreatePromocodeForm';
import { PromocodesTable } from './promocodes-table/PromocodesTable';
import styles from './Promocodes.module.scss';

export const Promocodes: FC = () => {
  const { t } = useTranslation();
  const [dialogOpened, setDialogOpened] = useState(false);
  const handleDialogOpen = useCallback(() => setDialogOpened(true), []);
  const handleDialogClose = useCallback(() => setDialogOpened(false), []);

  const myInfoQuery = UserQuery.useGetMyInfoQuery();

  if (!myInfoQuery.data?.roles?.find((x) => x == UserRoles.SuperAdmin)) {
    return <Navigate to={Links.Authorized.Dashboard} />;
  }

  return (
    <Stack
      className={styles.promocodes}
      direction={'column'}
      alignItems={'center'}
      alignContent={'center'}
    >
      <Stack className={styles.promocodesHeader} direction={'row'} justifyContent={'space-between'}>
        <div className={styles.promocodesTitle}>{t('Promocodes.Title')}</div>
        <IconButton onClick={handleDialogOpen}>
          <AddIcon />
        </IconButton>
      </Stack>
      <Dialog fullWidth={true} maxWidth={'sm'} open={dialogOpened} onClose={handleDialogClose}>
        <CreatePromocodeForm onClose={handleDialogClose} />
      </Dialog>
      <PromocodesTable />
    </Stack>
  );
};
