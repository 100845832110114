import { InvestigationQuery } from '@/services/api/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import constate from 'constate';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { updateExistingInvestigation } from '../../helpers/queries/query-helpers';

import { useRecipeParam } from './useRecipeParam';

export const [InvestigationProvider, useInvestigation] = constate(() => {
  const { t } = useTranslation();
  const { recipeId } = useRecipeParam();
  const queryClient = useQueryClient();

  const investigationQuery = InvestigationQuery.useGetQuery(recipeId);

  const createOrUpdateInvestigationMutation = useMutation(
    (text: string) => {
      return InvestigationQuery.Client.createOrUpdateInvestigation(recipeId, { text });
    },
    {
      onSuccess: (data) => {
        enqueueSnackbar(t('InvestigationEditor.SavedSuccessfullyMessage'), { variant: 'info' });
        updateExistingInvestigation(queryClient, recipeId, data);
      },
    },
  );

  return {
    investigation: investigationQuery.data,
    isInvestigationLoading: investigationQuery.isLoading,
    createOrUpdateInvestigation: createOrUpdateInvestigationMutation.mutateAsync,
    isCreateOrUpdateInvestigationLoading: createOrUpdateInvestigationMutation.isLoading,
  };
});
