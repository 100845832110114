import { IconButton as MuiIconButton } from '@mui/material';
import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';

import styles from './IconButton.module.scss';

export const IconButton = forwardRef<HTMLButtonElement, ComponentProps<typeof MuiIconButton>>(
  (props, ref) => (
    <MuiIconButton {...props} ref={ref} className={clsx(styles.iconButton, props.className)} />
  ),
);
