import { TextField as MuiTextField } from '@mui/material';
import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';

import styles from './TextField.module.scss';

export const TextField = forwardRef<HTMLDivElement, ComponentProps<typeof MuiTextField>>(
  (props, ref) => (
    <MuiTextField
      {...props}
      ref={ref}
      className={clsx(
        styles.textField,
        {
          [styles.primary]: props.color === 'primary',
        },
        props.className,
      )}
    />
  ),
);
