/*
 * build.REACT_APP_VERSION is defined at build time (vite.config.ts)
 * you could override it with env. variable REACT_APP_VERSION if you'd like to
 */
export const appVersion = () => import.meta.env.REACT_APP_VERSION! ?? build.REACT_APP_VERSION;
export const sentryDsn = () => import.meta.env.REACT_APP_SENTRY_DSN!;
export const additionalValueForDonationProgressBar = (): string =>
  import.meta.env.ADDITIONAL_VALUE_FOR_DONATION_PROGRESSBAR!;
export const goalToCollectByDonations = (): string => import.meta.env.GOAL_TO_COLLECT_BY_DONATIONS!;
export const ovoShopLink = (): string => import.meta.env.OVOSHOP_LINK!;
export const kononchukLink = (): string => import.meta.env.KONONCHUK_LINK!;
export const glazprosvetLink = (): string => import.meta.env.GLAZPROSVET_LINK!;
export const wikiLink = (): string => import.meta.env.WIKI_LINK!;
export const rraLink = (): string => import.meta.env.RRA_LINK!;
