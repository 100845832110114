import { ToggleButton as MuiToggleButton } from '@mui/material';
import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';

import styles from './ToggleButton.module.scss';

export const ToggleButton = forwardRef<HTMLButtonElement, ComponentProps<typeof MuiToggleButton>>(
  (props, ref) => (
    <MuiToggleButton {...props} ref={ref} className={clsx(styles.toggleButton, props.className)} />
  ),
);
