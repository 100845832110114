import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DonationCardState = {
  isOpened: boolean;
};

const initialState: DonationCardState = {
  isOpened: false,
};

export const donationCardSlice = createSlice({
  name: 'donationCard',
  initialState,
  reducers: {
    setDonationCardOpened: (state, action: PayloadAction<boolean>) => ({
      isOpened: action.payload,
    }),
  },
});

export const donationCardActions = donationCardSlice.actions;
