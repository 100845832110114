import CrossIcon from '@/assets/icons/cross.svg?react';
import AccountIcon from '@/assets/icons/user.svg?react';
import { Autocomplete } from '@/components/uikit/Autocomplete/Autocomplete';
import { FormError } from '@/components/uikit/FormError';
import { TextField } from '@/components/uikit/TextField/TextField';
import { useFocus } from '@/helpers/use-focus-hook';
import { restrictSpecialCharactersInNumberField } from '@/helpers/validation-helpers';
import { MaterialDto } from '@/services/api/api-client';
import { Grid, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import { FC, useCallback } from 'react';
import {
  Controller,
  FieldArrayPath,
  UseFieldArrayRemove,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { ValidateResult } from 'react-hook-form/dist/types/validator';
import { useTranslation } from 'react-i18next';

import {
  INGREDIENT_VALUE_REGEXP,
  INGREDIENTS_GRID_MATERIAL,
  INGREDIENTS_GRID_REMOVE,
  INGREDIENTS_GRID_VALUE,
  MAX_INGREDIENT_VALUE,
  MIN_INGREDIENT_VALUE,
} from '../constants';
import { RecipeCalculationForm } from '../RecipeCalculator';

import styles from './IngredientField.module.scss';

export type MaterialOption = Pick<MaterialDto, 'name' | 'id' | 'isPublic' | 'inInventory'>;

type IngredientFieldProps = {
  index: number;
  last: boolean;
  disabled?: boolean;
  isCalculationEmpty: boolean;
  materialOptions: MaterialOption[];
  name: FieldArrayPath<RecipeCalculationForm>;
  onRemove: UseFieldArrayRemove;
};

export const IngredientField: FC<IngredientFieldProps> = ({
  name,
  index,
  last,
  disabled,
  onRemove,
  materialOptions,
  isCalculationEmpty,
}) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { isSubmitting, errors },
  } = useFormContext<RecipeCalculationForm>();
  const ingredientsValues = useWatch({ control, name });

  const handleKeyDown = useCallback(restrictSpecialCharactersInNumberField, []);
  const [valueRef, setValueFocus] = useFocus();

  const handleValidate = useCallback((value: string): ValidateResult => {
    if (value && !INGREDIENT_VALUE_REGEXP.test(value)) {
      return t('RecipeCalculator.Error.InvalidIngredientValue', {
        min: MIN_INGREDIENT_VALUE,
        max: MAX_INGREDIENT_VALUE,
      });
    }
    return true;
  }, []);

  const valueError = errors?.[name]?.[index]?.value?.message;

  const ingredientsGridMaterial =
    disabled || isCalculationEmpty
      ? INGREDIENTS_GRID_MATERIAL + INGREDIENTS_GRID_REMOVE
      : INGREDIENTS_GRID_MATERIAL;

  return (
    <>
      {!(disabled || isCalculationEmpty) && (
        <Grid item xs={INGREDIENTS_GRID_REMOVE}>
          <IconButton
            size={'small'}
            data-last={last}
            onClick={() => onRemove(index)}
            className={styles.containerIcon}
            disabled={last || disabled || isSubmitting}
          >
            <CrossIcon />
          </IconButton>
        </Grid>
      )}
      <Grid item xs={ingredientsGridMaterial}>
        <Controller
          control={control}
          name={`${name}.${index}.material`}
          render={({ field }) => (
            <Autocomplete<MaterialOption, false, true>
              clearOnBlur
              blurOnSelect
              disableClearable
              value={field.value}
              onClose={field.onBlur}
              options={materialOptions}
              disabled={disabled || isSubmitting}
              noOptionsText={t('UIKit.NoResults')}
              className={styles.containerIngredient}
              onChange={(_, value) => {
                field.onChange(value);
                setValueFocus();
              }}
              getOptionLabel={(label) => label.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {field.value && !field.value.isPublic && (
                          <AccountIcon className={clsx(styles.privateIngredientIcon)} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  inputRef={field.ref}
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Stack
                    gap={1}
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    className={styles.ingredientListItem}
                  >
                    <Typography className={styles.ingredientListItemName}>{option.name}</Typography>
                    {!option.isPublic && <AccountIcon className={styles.privateIngredientIcon} />}
                  </Stack>
                </li>
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              groupBy={(x) => (x.inInventory ? t('Materials.MyInventory') : t('Materials.Public'))}
              getOptionDisabled={(option) =>
                ingredientsValues?.some((x) => x.material?.id == option.id)
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={INGREDIENTS_GRID_VALUE}>
        <TextField
          {...register(`${name}.${index}.value`, { validate: handleValidate })}
          inputRef={valueRef}
          type={'number'}
          error={!!valueError}
          onKeyDown={handleKeyDown}
          className={styles.containerValue}
          disabled={disabled || isSubmitting}
          // Needed to prevent unnecessary on-the-fly calculation if field is empty.
          onBlur={(e) => !e.target.value && e.preventDefault()}
          inputProps={{ min: MIN_INGREDIENT_VALUE, max: MAX_INGREDIENT_VALUE, step: 'any' }}
        />
      </Grid>
      {valueError && (
        <>
          <Grid item xs={INGREDIENTS_GRID_REMOVE} />
          <Grid item xs={INGREDIENTS_GRID_MATERIAL + INGREDIENTS_GRID_VALUE}>
            <FormError>{valueError}</FormError>
          </Grid>
        </>
      )}
    </>
  );
};
