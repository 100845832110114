import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { MapOrEntries, useLocalStorage, useMap } from 'usehooks-ts';

const RECIPE_FOLDERS_STATE_KEY = 'recipe_folders' as const;

const initRecipeFoldersStates: MapOrEntries<number, boolean> = [];

export const useRecipeFoldersStates = () => {
  const [recipeFolderStatesStorage, setRecipeFolderStatesStorage] = useLocalStorage(
    RECIPE_FOLDERS_STATE_KEY,
    JSON.stringify(initRecipeFoldersStates),
  );

  const [recipeFoldersStates, recipeFolderActions] = useMap<number, boolean>(
    JSON.parse(recipeFolderStatesStorage),
  );

  useEffect(() => {
    if (JSON.stringify(Array.from(recipeFoldersStates)) !== recipeFolderStatesStorage) {
      setRecipeFolderStatesStorage(JSON.stringify(Array.from(recipeFoldersStates)));
    }
  }, [recipeFoldersStates]);

  useEffect(() => {
    recipeFolderActions.setAll(JSON.parse(recipeFolderStatesStorage));
  }, [recipeFolderStatesStorage]);

  const toggleFolderCollapse = useCallback(
    (folderId: number) => {
      const currentFolderState = recipeFoldersStates.get(folderId);
      recipeFolderActions.set(folderId, !currentFolderState);
    },
    [recipeFolderActions, recipeFoldersStates],
  );

  return {
    recipeFoldersStates,
    recipeFolderActions,
    toggleFolderCollapse,
  };
};

export const usePublicFolderParam = () => {
  const { folderId } = useParams();

  return {
    publicFolderId: folderId,
  };
};
