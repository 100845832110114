import { Divider as MuiDivider } from '@mui/material';
import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';

import styles from './Divider.module.scss';

export const Divider = forwardRef<HTMLHRElement, ComponentProps<typeof MuiDivider>>(
  (props, ref) => (
    <MuiDivider {...props} ref={ref} className={clsx(styles.divider, props.className)} />
  ),
);
