import { Links } from '@/application/constants/links';
import { NotFoundPage } from '@pages/not-found-page/NotFoundPage';
import { EmailConfirmationPage } from '@pages/unauthorized/email-confirmation/EmailConfirmationPage';
import { LoginPage } from '@pages/unauthorized/login-page/LoginPage';
import { PasswordForgottenPage } from '@pages/unauthorized/password-forgotten/PasswordForgottenPage';
import { PasswordResetPage } from '@pages/unauthorized/password-reset/PasswordResetPage';
import { PrivacyPage } from '@pages/unauthorized/privacy-page/PrivacyPage';
import { RequisitesPage } from '@pages/unauthorized/requisites-page/RequisitesPage';
import { UnauthorizedRootPage } from '@pages/unauthorized/root-page/UnauthorizedRootPage';
import { UserRegistrationPage } from '@pages/unauthorized/user-registration/UserRegistrationPage';
import { createBrowserRouter } from 'react-router-dom';

import { HomePage } from './home-page/HomePage';

export const anonymousRoutes = () =>
  createBrowserRouter([
    {
      path: '*',
      element: <NotFoundPage />,
    },
    {
      path: Links.Unauthorized.Home,
      element: <HomePage />,
    },
    {
      path: Links.Unauthorized.Privacy,
      element: <PrivacyPage />,
    },
    {
      path: Links.Unauthorized.Requisites,
      element: <RequisitesPage />,
    },
    {
      element: <UnauthorizedRootPage />,
      children: [
        {
          path: Links.Unauthorized.Login,
          element: <LoginPage />,
        },
        {
          path: Links.Authorized.Folder,
          element: <LoginPage />,
        },
        {
          path: `${Links.Authorized.Recipe}/*`,
          element: <LoginPage />,
        },
        {
          path: Links.Unauthorized.UserRegistration,
          element: <UserRegistrationPage />,
        },
        {
          path: Links.Unauthorized.PasswordForgotten,
          element: <PasswordForgottenPage />,
        },
        {
          path: Links.Unauthorized.PasswordReset,
          element: <PasswordResetPage />,
        },
        {
          path: Links.Unauthorized.EmailConfirmation,
          element: <EmailConfirmationPage />,
        },
      ],
    },
  ]);
