import i18n from 'i18next';
import React from 'react';

// Do not allow to type any special characters, such as: 'e', '+', '-'.
export const restrictSpecialCharactersInNumberField = (e: React.KeyboardEvent<HTMLInputElement>) => {
  return ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
};

export const validateConfirmPassword = (password: string, confirmPassword: string) => {
  if (password !== confirmPassword) {
    return i18n.t('Errors.InvalidConfirmPassword');
  }
};

export const validateEmail = (email: string) => {
  const regex =
    /(?:[a-z\d!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z\d!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z\d](?:[a-z\d-]*[a-z\d])?\.)+[a-z\d](?:[a-z\d-]*[a-z\d])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?|[a-z\d-]*[a-z\d]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/;
  if (!regex.test(email)) {
    return i18n.t('Errors.InvalidEmail');
  }
};
