import type { BlockerFunction } from '@remix-run/router';
import { useCallback } from 'react';
import { useBlocker as useReactRouterBlocker } from 'react-router';

export const useBlocker = (shouldBlock: boolean) => {
  const shouldBlockCallback = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      const blocked = shouldBlock && currentLocation.pathname !== nextLocation.pathname;
      if (blocked) {
        window.onbeforeunload = () => '';
      } else {
        window.onbeforeunload = null;
      }
      return blocked;
    },
    [shouldBlock],
  );

  const blocker = useReactRouterBlocker(shouldBlockCallback);

  const keepHere = useCallback(() => {
    blocker.reset?.();
  }, [blocker]);

  const proceedForward = useCallback(() => {
    blocker.proceed?.();
  }, [blocker]);

  return {
    blocked: blocker.state === 'blocked',
    keepHere,
    proceedForward,
  };
};
