import { ToggleButton } from '@/components/uikit/ToggleButton/ToggleButton';
import { ToggleButtonGroup } from '@/components/uikit/ToggleButtonGroup/ToggleButtonGroup';
import { useRecipeParam } from '@/hooks/recipe/useRecipeParam';
import { useSelectedTab } from '@/hooks/recipe/useSelectedTab';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export enum Tab {
  Calculators = 'Calculators',
  Investigation = 'Investigation',
}

type Props = {
  disabled: boolean;
};

export const TabSelector: FC<Props> = ({ disabled }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { recipeId } = useRecipeParam();
  const { selectedTab } = useSelectedTab();

  return (
    <ToggleButtonGroup
      exclusive
      size={'small'}
      color={'primary'}
      value={selectedTab}
      disabled={disabled}
    >
      <ToggleButton
        value={Tab.Calculators}
        onClick={() => navigate(`..\\${recipeId}\\calculations`, { replace: true })}
      >
        {t(`TabSelector.${Tab.Calculators}`)}
      </ToggleButton>
      <ToggleButton
        value={Tab.Investigation}
        onClick={() => navigate(`..\\${recipeId}\\investigation`, { replace: true })}
      >
        {t(`TabSelector.${Tab.Investigation}`)}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
