import { Tooltip as MuiTooltip } from '@mui/material';
import { ComponentProps, forwardRef, ReactNode } from 'react';

type Props = Omit<ComponentProps<typeof MuiTooltip>, 'children'> & {
  children: ReactNode;
};

export const Tooltip = forwardRef<HTMLSpanElement, Props>(({ children, ...props }, ref) => (
  <MuiTooltip {...props} ref={ref}>
    <span>{children}</span>
  </MuiTooltip>
));
