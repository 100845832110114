import KafootkaImage from '@/assets/img/kafootka.svg?react';
import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../uikit/Button/Button';
import { Dialog } from '../uikit/Dialog/Dialog';

import styles from './RemoveDialog.module.scss';

type OwnProps = {
  isOpened: boolean;
  removeTitle?: string;
  disabled?: boolean;
  onClose: () => void;
  onRemove: () => void;
};

export const RemoveDialog: FC<OwnProps> = ({
  isOpened,
  onClose,
  onRemove,
  removeTitle,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth={false} maxWidth={'md'} open={isOpened} onClose={onClose}>
      <Stack direction={'row'}>
        <KafootkaImage className={styles.kafootkaImage} />
        <div>
          <DialogTitle sx={{ fontWeight: 600, fontSize: '16px' }}>{removeTitle}</DialogTitle>
          <DialogContent sx={{ fontWeight: 400, fontSize: '16px', marginTop: '30px' }}>
            <>
              <div>{t('RemoveDialog.Message_Line1')}</div>
              <div>{t('RemoveDialog.Message_Line2')}</div>
            </>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', margin: '30px 10px 0 10px' }}>
            <Button textColor={'#000'} onClick={onClose} disabled={disabled}>
              {t('UIKit.Cancel')}
            </Button>
            <Button onClick={onRemove} disabled={disabled}>
              {t('RemoveDialog.RemoveButton')}
            </Button>
          </DialogActions>
        </div>
      </Stack>
    </Dialog>
  );
};
