import { useAdvancedForm } from '@/helpers/form/useAdvancedForm';
import {
  CalculationHistoryRecordDto,
  FormulaViewType,
  IngredientForCalculationDto,
  RecipeCalculationDto,
  RecipeCalculationQuery,
} from '@/services/api/api-client';
import { QueryKey, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import {
  Control,
  DefaultValues,
  FieldArrayPath,
  useFieldArray,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  addNewRecipeCalculation,
  removeExistingRecipeCalculation,
  updateExistingRecipeCalculation,
} from '../../../../../common/helpers/queries/query-helpers';
import { useRecipeCalculations } from '../../../../../common/hooks/recipe/useRecipeCalculations';

import { buildIngredientDtos, buildIngredientFields, emptyIngredientField } from './helpers';
import { IngredientFormField, RecipeCalculationForm } from './RecipeCalculator';

export const useRecipeCalculatorForm = (
  recipeId: number,
  calculationId: number,
  defaultValues?: DefaultValues<RecipeCalculationForm>,
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useAdvancedForm<RecipeCalculationForm>(
    useCallback(async (data) => {
      const updatedRecipeCalculation = await RecipeCalculationQuery.Client.update(
        recipeId,
        calculationId,
        {
          recipe: [
            ...buildIngredientDtos(data.mainIngredients, false),
            ...buildIngredientDtos(data.additionalIngredients, true),
          ],
          name: data.name,
          formulaViewType: data.viewType,
          includeAdditionsIntoCalculations: data.includeAdditionsIntoCalculations,
          isLocked: data.isLocked,
        },
      );
      updateExistingRecipeCalculation(queryClient, recipeId, updatedRecipeCalculation);
      enqueueSnackbar(
        t('RecipeCalculator.Notification.CalculationSaved', { calculatorName: data.name }),
        {
          variant: 'info',
        },
      );
    }, []),
    {
      mode: 'all',
      defaultValues,
    },
  );
};

export const getOnTheFlyCalculationQueryKey = (
  dtos: IngredientForCalculationDto[],
  recipeId: number,
  calculationId: number,
  viewType: FormulaViewType,
  includeAdditionsIntoCalculations: boolean,
): QueryKey => [
  'on-the-fly',
  recipeId,
  viewType,
  includeAdditionsIntoCalculations,
  calculationId,
  dtos,
];

export const useIngredientDtos = (
  mainIngredientsValues: IngredientFormField[],
  additionalIngredientsValues: IngredientFormField[],
) =>
  useMemo(
    () => [
      ...buildIngredientDtos(mainIngredientsValues, false),
      ...buildIngredientDtos(additionalIngredientsValues, true),
    ],
    [mainIngredientsValues, additionalIngredientsValues],
  );

export const useOnTheFlyCalculationQuery = (
  dtos: IngredientForCalculationDto[],
  recipeId: number,
  calculationId: number,
  viewType: FormulaViewType,
  includeAdditionsIntoCalculations: boolean,
  isFormValid: boolean,
) =>
  useQuery(
    getOnTheFlyCalculationQueryKey(
      dtos,
      recipeId,
      calculationId,
      viewType,
      includeAdditionsIntoCalculations,
    ),
    () =>
      RecipeCalculationQuery.Client.calculateOnTheFly({
        recipe: dtos,
        formulaViewType: viewType,
        includeAdditionsIntoCalculations: includeAdditionsIntoCalculations,
      }),
    {
      keepPreviousData: true,
      enabled: isFormValid && dtos.length > 0,
    },
  );

export const useReduceTo100Mutation = (
  mainIngredientsValues: IngredientFormField[],
  setValue: UseFormSetValue<RecipeCalculationForm>,
) =>
  useMutation(
    (ingredients: IngredientForCalculationDto[]) =>
      RecipeCalculationQuery.Client.reduceRecipeTo100(ingredients),
    {
      onSuccess: (result) => {
        result
          .filter((x) => !x.isAddition)
          .forEach((ingredient) => {
            const index = mainIngredientsValues.findIndex(
              (x) => x.material?.id == ingredient.material.id,
            );
            if (index !== -1) {
              setValue(`mainIngredients.${index}.value`, ingredient.value.toString(), {
                shouldDirty: true,
              });
            }
          });
      },
    },
  );

export const useRemoveRecipeCalculationMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { removeStullPoint } = useRecipeCalculations();

  return useMutation(
    (params: { recipeId: number; calculationId: number }) =>
      RecipeCalculationQuery.Client.removeRecipeCalculation(params.recipeId, params.calculationId),
    {
      onSuccess: (_, params) => {
        enqueueSnackbar(t('RecipeCalculator.Notification.CalculationRemoved'), {
          variant: 'info',
        });
        removeExistingRecipeCalculation(queryClient, params.recipeId, params.calculationId);
        removeStullPoint(params.calculationId);
      },
    },
  );
};

export const useCopyRecipeCalculatorMutation = (recipeId: number) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (params: {
      name: string;
      viewType: FormulaViewType;
      includeAdditionsIntoCalculations: boolean;
      isLocked: boolean;
      ingredients: IngredientForCalculationDto[];
    }) =>
      RecipeCalculationQuery.Client.create(recipeId, {
        recipe: params.ingredients,
        formulaViewType: params.viewType,
        includeAdditionsIntoCalculations: params.includeAdditionsIntoCalculations,
        isLocked: params.isLocked,
        name: t('Page.Recipe.CopiedCalculation', { name: params.name }),
      }),
    {
      onSuccess: (copiedCalculation) => {
        enqueueSnackbar(t('RecipeCalculator.Notification.CalculationCopied'), {
          variant: 'info',
        });
        addNewRecipeCalculation(queryClient, recipeId, copiedCalculation);
      },
    },
  );
};

export const useIngredientsFields = (
  control: Control<RecipeCalculationForm>,
  name: FieldArrayPath<RecipeCalculationForm>,
) => {
  // Setting up dynamic fields for ingredients.
  const fields = useFieldArray({
    name: name,
    control: control,
  });

  return {
    ...fields,
    add: useCallback(() => fields.append(emptyIngredientField, { shouldFocus: false }), [fields]),
  };
};

export const useCalculationFormDefaultValues = (
  dto: RecipeCalculationDto,
  historyRecordDto: CalculationHistoryRecordDto,
): DefaultValues<RecipeCalculationForm> | undefined =>
  useMemo(
    () => ({
      name: historyRecordDto.name,
      viewType: dto.formulaViewType,
      isLocked: dto.isLocked,
      includeAdditionsIntoCalculations: dto.includeAdditionsIntoCalculations,
      mainIngredients: [
        ...buildIngredientFields(historyRecordDto.recipe, false),
        emptyIngredientField,
      ],
      additionalIngredients: [
        ...buildIngredientFields(historyRecordDto.recipe, true),
        emptyIngredientField,
      ],
    }),
    [
      dto.formulaViewType,
      dto.includeAdditionsIntoCalculations,
      dto.isLocked,
      dto.name,
      historyRecordDto.recipe,
    ],
  );

export const useCalculationFormValues = (
  control: Control<RecipeCalculationForm>,
): RecipeCalculationForm => {
  const name = useWatch({ control: control, name: 'name' });
  const viewType = useWatch({ control: control, name: 'viewType' });
  const isLocked = useWatch({ control: control, name: 'isLocked' });
  const mainIngredients = useWatch({ control: control, name: 'mainIngredients' });
  const additionalIngredients = useWatch({ control: control, name: 'additionalIngredients' });
  const includeAdditionsIntoCalculations = useWatch({
    control: control,
    name: 'includeAdditionsIntoCalculations',
  });
  return {
    name,
    viewType,
    includeAdditionsIntoCalculations,
    isLocked,
    mainIngredients,
    additionalIngredients,
  };
};
