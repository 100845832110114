import { Menu as MuiMenu } from '@mui/material';
import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';

import styles from './Menu.module.scss';

type Props = ComponentProps<typeof MuiMenu>;

export const Menu = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <MuiMenu {...props} ref={ref} className={clsx(styles.container)} />
));
