import { InvestigationProvider } from '@/hooks/recipe/useInvestigation';
import { RecipeProvider } from '@/hooks/recipe/useRecipe';
import { RecipeCalculationsProvider } from '@/hooks/recipe/useRecipeCalculations';
import { RecipeParamProvider } from '@/hooks/recipe/useRecipeParam';
import { SelectedTabProvider } from '@/hooks/recipe/useSelectedTab';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';
import { FC, lazy } from 'react';

const RecipePageDefaultView = lazy(() => import('./default-view/RecipePageDefaultView'));

const RecipePageMobileView = lazy(() => import('./mobile-view/RecipePageMobileView'));

export const RecipePage: FC = () => {
  const isMobile = useMobileMediaQuery();

  return (
    <RecipeParamProvider>
      <RecipeProvider>
        <RecipeCalculationsProvider>
          <InvestigationProvider>
            <SelectedTabProvider>
              {isMobile ? <RecipePageMobileView /> : <RecipePageDefaultView />}
            </SelectedTabProvider>
          </InvestigationProvider>
        </RecipeCalculationsProvider>
      </RecipeProvider>
    </RecipeParamProvider>
  );
};
