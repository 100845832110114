import { getFolderRoute, Links } from '@/application/constants/links';
import { useScopedTranslation } from '@/application/localization/useScopedTranslation';
import { LinearProgress } from '@/components/uikit/LinearProgress/LinearProgress';
import { MaterialQuery, RecipeQuery } from '@/services/api/api-client';
import { PrintRecipeCalculation } from '@pages/authorized/recipe-page/common/recipe-header/print-recipe-dialog/print-recipe-component/PrintRecipeCalculation';
import { PrintInvestigation } from '@pages/authorized/recipe-page/common/recipe-header/print-recipe-dialog/PrintInvestigation';
import { useIsFetching } from '@tanstack/react-query';
import { FC, Fragment, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';

import { RecipeCalculationsProvider } from '../../../hooks/recipe/useRecipeCalculations';
import { RecipeParamProvider } from '../../../hooks/recipe/useRecipeParam';

import styles from './ExportRecipes.module.scss';

export const ExportRecipes: FC = () => {
  const navigate = useNavigate();
  const fetchingQueries = useIsFetching();
  const { t } = useScopedTranslation('ExportRecipes');
  const { folderId: folderPublicId } = useParams<'folderId'>();

  const allRecipesForExportQuery = RecipeQuery.useGetAllRecipesForExportQuery(folderPublicId);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () =>
      folderPublicId
        ? navigate(getFolderRoute(folderPublicId))
        : navigate(Links.Authorized.UserProfile),
  });

  const materialsQuery = MaterialQuery.useSearchQuery(undefined, { refetchOnMount: false });

  const printRecipeDtos = useMemo(
    () => allRecipesForExportQuery.data ?? [],
    [allRecipesForExportQuery.data],
  );

  useEffect(() => {
    if (fetchingQueries === 0) {
      setTimeout(() => handlePrint(), 200);
    }
  }, [handlePrint, fetchingQueries]);

  return (
    <>
      {fetchingQueries > 0 && (
        <>
          <LinearProgress isAbsolute />
          <div className={styles.loadingMessage}>{t('LoadingMessage')}</div>
        </>
      )}
      {allRecipesForExportQuery.isSuccess && (
        <div className={styles.printContainer}>
          <div ref={componentRef}>
            {printRecipeDtos.map((dto, index) => (
              <Fragment key={index}>
                {dto.calculations?.map((recipeCalc, index) => (
                  <div key={index}>
                    <div className={styles.pageBreak} />
                    <PrintRecipeCalculation
                      printRecipeCalculationInfo={recipeCalc}
                      materials={materialsQuery.data}
                    />
                  </div>
                ))}
                <RecipeParamProvider recipeId={dto.recipeId}>
                  <RecipeCalculationsProvider>
                    {dto.investigation && (
                      <>
                        <PrintInvestigation
                          recipeName={dto.investigation.recipeName}
                          recipeDescription={dto.investigation.recipeDescription}
                          text={dto.investigation.text}
                        />
                      </>
                    )}
                  </RecipeCalculationsProvider>
                </RecipeParamProvider>
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
