import MorwukImage from '@/assets/img/morwük.svg?react';
import { convertToErrorString } from '@/helpers/form/useErrorHandler';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { Button } from '../Button/Button';

import styles from './Loading.module.scss';

type ErrorBoundaryFallbackProps = {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
};
export const errorBoundaryFallbackFunction = (props: ErrorBoundaryFallbackProps) => (
  <ErrorBoundaryFallback {...props} />
);
export const ErrorBoundaryFallback = (props: ErrorBoundaryFallbackProps) => {
  const location = useLocation();
  const i18n = useTranslation();
  const [initialLocation] = useState(location);
  useEffect(() => {
    if (initialLocation !== location) {
      props.resetError();
    }
  }, [initialLocation, location]);

  return (
    <div className={styles.errorBoundary}>
      <div className={styles.flexLoadingData} data-test-id='loading-error'>
        <Stack alignItems={'center'}>
          <MorwukImage className={styles.errorBoundaryMorwukImage} />
          <div className={styles.errorBoundaryTitle}>{convertToErrorString(props.error)}</div>
          <div>
            <Button
              onClick={async () => {
                props.resetError();
              }}
              color={'secondary'}
              variant={'outlined'}
            >
              {i18n.t('Suspense.Reload')}
            </Button>
          </div>
        </Stack>
      </div>
    </div>
  );
};
