import { createFileParameter } from '@/helpers/api-helpers';
import { RecipePhotosQuery } from '@/services/api/api-client';
import { useMutation } from '@tanstack/react-query';
import { LexicalEditor } from 'lexical';

import { INSERT_IMAGE_COMMAND } from '../images-plugin/ImagesPlugin';

export const useAddPhotoToInvestigation = (recipeId: number, editor: LexicalEditor) =>
  useMutation(
    (file: File) => {
      const isForInvestigationEditor = true;
      return RecipePhotosQuery.Client.addPhoto(
        recipeId,
        isForInvestigationEditor,
        createFileParameter(file),
      );
    },
    {
      onSuccess: (photo) => {
        editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
          altText: '',
          src: `/api/recipe/${recipeId}/photo/${photo.id}`,
        });
      },
    },
  );
