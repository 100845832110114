import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { EditorState } from 'lexical';
import React, { FC, useEffect } from 'react';

type Props = {
  initialValue: string | null;
  onChange: (value: string) => void;
};

export const ReactFormConnectorPlugin: FC<Props> = ({ initialValue, onChange }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (initialValue) {
      // Some hack to fix issue with flushSync during setEditorState
      // https://github.com/facebook/lexical/discussions/3536
      setTimeout(() => {
        const initialEditorState = editor.parseEditorState(initialValue);
        editor.setEditorState(initialEditorState);
      }, 0);
    }
  }, []);

  const handleChange = (editorState: EditorState) => {
    onChange(JSON.stringify(editorState));
  };

  return <OnChangePlugin onChange={handleChange} />;
};
