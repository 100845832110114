import { Divider } from '@/components/uikit/Divider/Divider';
import { Tooltip } from '@/components/uikit/Tooltip/Tooltip';
import { FormulaViewType, RecipeCalculationBaseDto } from '@/services/api/api-client.types';
import { Stack } from '@mui/material';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RecipeCalculationForm } from '../RecipeCalculator';

import styles from './SegerFormula.module.scss';
import { SegerFormulaItem } from './SegerFormulaItem';
import { ViewSelector } from './ViewSelector';

type SegerFormulaProps = {
  disabled?: boolean;
  viewType: FormulaViewType;
  calculation?: RecipeCalculationBaseDto;
};

export const SegerFormula: FC<SegerFormulaProps> = ({ disabled, viewType, calculation }) => {
  const { t } = useTranslation();
  const {
    formState: { isSubmitting },
  } = useFormContext<RecipeCalculationForm>();

  const alcali = calculation?.formula?.Alcali && Object.entries(calculation.formula.Alcali);
  const aEarth = calculation?.formula?.AEarth && Object.entries(calculation.formula.AEarth);
  const stabs = calculation?.formula?.Stabs && Object.entries(calculation.formula.Stabs);
  const gFormers = calculation?.formula?.GFormers && Object.entries(calculation.formula.GFormers);
  const other = calculation?.formula?.Other && Object.entries(calculation.formula.Other);

  return (
    <>
      <Stack direction={'row'} spacing={1} divider={<Divider orientation={'vertical'} flexItem />}>
        <Stack flexGrow={1}>
          <div className={styles.segerFormulaHeader}>{`${t('Oxide.R2O')}/${t('Oxide.RO')}`}</div>
          {alcali?.map(([oxide, value]) => (
            <SegerFormulaItem key={oxide} viewType={viewType} oxide={oxide} value={value} />
          ))}
          <div className={styles.segerFormulaHeader} />
          {aEarth?.map(([oxide, value]) => (
            <SegerFormulaItem key={oxide} viewType={viewType} oxide={oxide} value={value} />
          ))}
        </Stack>
        <Stack flexGrow={1}>
          <div className={styles.segerFormulaHeader}>{t('Oxide.R2O3')}</div>
          {stabs?.map(([oxide, value]) => (
            <SegerFormulaItem key={oxide} viewType={viewType} oxide={oxide} value={value} />
          ))}
        </Stack>
        <Stack flexGrow={1}>
          <div className={styles.segerFormulaHeader}>{t('Oxide.RO2')}</div>
          {gFormers?.map(([oxide, value]) => (
            <SegerFormulaItem key={oxide} viewType={viewType} oxide={oxide} value={value} />
          ))}
          <div className={styles.segerFormulaHeader}>{t('OxideRole.Other')}</div>
          {other?.map(([oxide, value]) => (
            <SegerFormulaItem key={oxide} viewType={viewType} oxide={oxide} value={value} />
          ))}
        </Stack>
      </Stack>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        className={styles.segerFormulaOxideSums}
        spacing={2}
      >
        <Stack direction={'row'} spacing={2}>
          <div>
            <span className={styles.segerFormulaOxideSum}>{`${t('Oxide.R2O')}/${t(
              'Oxide.RO',
            )}: `}</span>
            {calculation?.alcaliSum && calculation?.aEarthSum
              ? `${calculation?.alcaliSum.toFixed(3)}/${calculation?.aEarthSum.toFixed(3)}`
              : '—'}
          </div>
          <div>
            <span className={styles.segerFormulaOxideSum}>{`${t(
              'OxideRole.SiliconAluminumOxidesRatio',
            )}: `}</span>
            {calculation && calculation.siliconAluminumOxidesRatio !== 0
              ? calculation?.siliconAluminumOxidesRatio
              : '—'}
          </div>
          <div>
            <span className={styles.segerFormulaOxideSum}>{`${t(
              'RecipeCalculator.ThermalExpansion',
            )}: `}</span>
            <Tooltip title={t('RecipeCalculator.ThermalExpansionTooltip')}>
              <>
                {calculation?.molarThermalExpansion && calculation?.molecularMassThermalExpansion
                  ? `${calculation?.molarThermalExpansion.toFixed(2)}` +
                    `/${calculation?.molecularMassThermalExpansion.toFixed(2)}`
                  : '—'}
              </>
            </Tooltip>
          </div>
        </Stack>
        {!disabled && (
          <Controller
            name={'viewType'}
            render={({ field }) => <ViewSelector {...field} disabled={disabled || isSubmitting} />}
          />
        )}
      </Stack>
    </>
  );
};
