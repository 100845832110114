import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';
import { FC, lazy } from 'react';

const RequisitesPageDefaultView = lazy(() => import('./default-view/RequisitesPageDefaultView'));
const RequisitesPageMobileView = lazy(() => import('./mobile-view/RequisitesPageMobileView'));

export const RequisitesPage: FC = () => {
  const isMobile = useMobileMediaQuery();
  return isMobile ? <RequisitesPageMobileView /> : <RequisitesPageDefaultView />;
};
