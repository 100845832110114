import { LinearProgress as MuiLinearProgress } from '@mui/material';
import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';

import styles from './LinearProgress.module.scss';

type Props = ComponentProps<typeof MuiLinearProgress> & {
  isAbsolute?: boolean;
};

export const LinearProgress = forwardRef<unknown, Props>(({ isAbsolute, ...props }, ref) => (
  <MuiLinearProgress
    {...props}
    ref={ref}
    className={clsx({ [styles.absolute]: isAbsolute }, props.className)}
  />
));
