import { AutocompleteProps, ChipTypeMap, Autocomplete as MuiAutocomplete } from '@mui/material';
import clsx from 'clsx';
import { ComponentProps, FC, forwardRef } from 'react';

import styles from './Autocomplete.module.scss';

interface AutocompleteForwardRefType extends FC<ComponentProps<typeof MuiAutocomplete>> {
  <
    T,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
  >(
    props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  ): ReturnType<FC<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>>>;
}

export const Autocomplete: AutocompleteForwardRefType = forwardRef((props, ref) => (
  <MuiAutocomplete {...props} ref={ref} className={clsx(styles.autocomplete, props.className)} />
));
