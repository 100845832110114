import { useScopedTranslation } from '@/application/localization/useScopedTranslation';
import { ToggleButton } from '@/components/uikit/ToggleButton/ToggleButton';
import { FormulaViewType } from '@/services/api/api-client';
import { ToggleButtonGroup } from '@mui/material';
import React, { forwardRef } from 'react';
import { ControllerRenderProps, useFormContext } from 'react-hook-form';

import { RecipeCalculationForm } from '../RecipeCalculator';

type ViewSelectorProps = ControllerRenderProps<RecipeCalculationForm, 'viewType'> & {
  disabled?: boolean;
};

export const ViewSelector = forwardRef<unknown, ViewSelectorProps>(
  ({ value, onChange, disabled }, ref) => {
    const { t } = useScopedTranslation('RecipeCalculator');
    const {
      formState: { isSubmitting },
    } = useFormContext<RecipeCalculationForm>();
    const viewOptions = Object.values(FormulaViewType);

    const handleChange = (option: FormulaViewType | null) => {
      if (option) {
        onChange(option);
      }
    };

    return (
      <ToggleButtonGroup
        exclusive
        ref={ref}
        value={value}
        size={'small'}
        color={'primary'}
        disabled={disabled || isSubmitting}
        onChange={(_, value) => handleChange(value)}
      >
        {viewOptions.map((option) => (
          <ToggleButton key={option} value={option} onClick={() => handleChange(option)}>
            {t(`View.${option}`)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  },
);
