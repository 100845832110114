import constate from 'constate';
import { useState } from 'react';

export const [DonationCardsProvider, useDonationCards] = constate(() => {
  const [userIsNotAllowedToCopyRecipe, setUserIsNotAllowedToCopyRecipe] = useState(false);
  const [maximumPhotosUploadCountReached, setMaximumPhotosUploadCountReached] = useState(false);
  const [maxRecipeCalculatorsPerRecipeCountReached, setMaxRecipeCalculatorsPerRecipeCountReached] =
    useState(false);
  const [
    userIsNotAllowedToCopyRecipeBecauseOfCalcs,
    setUserIsNotAllowedToCopyRecipeBecauseOfCalcs,
  ] = useState(false);
  const [
    addingNewCalculationsIntoInvestigationForbidden,
    setAddingNewCalculationsIntoInvestigationForbidden,
  ] = useState(false);

  return {
    userIsNotAllowedToCopyRecipe,
    maximumPhotosUploadCountReached,
    maxRecipeCalculatorsPerRecipeCountReached,
    userIsNotAllowedToCopyRecipeBecauseOfCalcs,
    addingNewCalculationsIntoInvestigationForbidden,
    setUserIsNotAllowedToCopyRecipe,
    setMaximumPhotosUploadCountReached,
    setMaxRecipeCalculatorsPerRecipeCountReached,
    setUserIsNotAllowedToCopyRecipeBecauseOfCalcs,
    setAddingNewCalculationsIntoInvestigationForbidden,
  };
});
