import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import { FC, useMemo } from 'react';

import { getChartOptions } from './ChartOptions';

HighchartsMore(Highcharts);

export type StullChartPoint = {
  siO2Value: number;
  al2O3Value: number;
  recipeCalculationId: number;
  recipeCalculationName: string;
  recipeId: number;
};

export type Props = {
  recipeCalculationId?: number;
  stullPoints: StullChartPoint[];
  showMinimized?: boolean;
  className?: string;
};

export const StullChart: FC<Props> = ({
  recipeCalculationId,
  stullPoints,
  showMinimized,
  className,
}) => {
  const options = useMemo(
    () => getChartOptions(stullPoints, recipeCalculationId, showMinimized),
    [stullPoints, recipeCalculationId, showMinimized],
  );

  return (
    <div className={className}>
      {options && <HighchartsReact highcharts={Highcharts} options={options} />}
    </div>
  );
};
