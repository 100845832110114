import MoveIcon from '@/assets/icons/corner-up-left.svg?react';
import { Autocomplete } from '@/components/uikit/Autocomplete/Autocomplete';
import { Button } from '@/components/uikit/Button/Button';
import { Dialog } from '@/components/uikit/Dialog/Dialog';
import { TextField } from '@/components/uikit/TextField/TextField';
import { RecipeCalculationQuery, RecipeFolderQuery, RecipeQuery } from '@/services/api/api-client';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { removeExistingRecipeCalculation } from '../../../../../../common/helpers/queries/query-helpers';
import { useRecipeCalculations } from '../../../../../../common/hooks/recipe/useRecipeCalculations';

import styles from './MoveRecipeCalculatorDialog.module.scss';

type OwnProps = {
  isOpened: boolean;
  onClose: () => void;
  recipeId: number;
  recipeCalculationId: number;
  recipeCalculatorName: string;
};

interface SelectOption {
  id: number;
  name: string;
}

export const MoveRecipeCalculatorDialog: FC<OwnProps> = ({
  isOpened,
  onClose,
  recipeId,
  recipeCalculationId,
  recipeCalculatorName,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { removeStullPoint } = useRecipeCalculations();

  const [selectedFolder, setSelectedFolder] = useState<SelectOption | null>(null);
  const [selectedRecipe, setSelectedRecipe] = useState<SelectOption | null>(null);
  const [shouldBeCopied, setShouldBeCopied] = useState<boolean>(false);
  const [recipeOptions, setRecipeOptions] = useState<SelectOption[]>([]);

  const recipeFoldersForUserQuery = RecipeFolderQuery.useGetRecipeFoldersForUserQuery({
    refetchOnMount: false,
  });

  const moveRecipeCalculationMutation = useMutation(
    (params: {
      targetRecipeId: number;
      shouldBeCopied: boolean;
      recipeId: number;
      recipeCalculationId: number;
    }) =>
      RecipeCalculationQuery.Client.move(
        params.recipeId,
        params.recipeCalculationId,
        params.targetRecipeId,
        params.shouldBeCopied,
      ),
    {
      onSuccess: (data, variables, context) => {
        if (!variables.shouldBeCopied) {
          removeExistingRecipeCalculation(
            queryClient,
            variables.recipeId,
            variables.recipeCalculationId,
          );
          removeStullPoint(variables.recipeCalculationId);
        }
        queryClient.invalidateQueries(RecipeQuery.getQueryKey(variables.targetRecipeId));
        onClose();
      },
    },
  );
  const handleMoveRecipe = useCallback(() => {
    if (selectedRecipe) {
      moveRecipeCalculationMutation.mutate({
        targetRecipeId: selectedRecipe.id,
        shouldBeCopied: shouldBeCopied,
        recipeId: recipeId,
        recipeCalculationId: recipeCalculationId,
      });
    }
  }, [
    selectedRecipe,
    moveRecipeCalculationMutation,
    shouldBeCopied,
    recipeId,
    recipeCalculationId,
  ]);

  useEffect(() => {
    if (selectedFolder) {
      const recipeFolderDto = recipeFoldersForUserQuery.data?.find(
        (x) => x.id === selectedFolder.id,
      );
      const options: SelectOption[] | undefined = recipeFolderDto?.recipes?.map((x) => ({
        id: x.id,
        name: x.name,
      }));
      setRecipeOptions(options ?? []);
    }
  }, [recipeFoldersForUserQuery.data, selectedFolder]);

  const foldersOptions: SelectOption[] | undefined = recipeFoldersForUserQuery.data?.map((x) => ({
    id: x.id,
    name: x.name,
  }));

  const handleOnFolderChanged = (value: SelectOption | null) => {
    if (value) {
      setSelectedFolder(value);
      setSelectedRecipe(null);
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={isOpened} onClose={onClose}>
      <DialogTitle>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'left'}>
          <MoveIcon />
          <div className={styles.moveRecipeCalculatorTitle}>
            {t('MoveRecipeCalculatorDialog.Title', { name: recipeCalculatorName })}
          </div>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-around'}>
          {foldersOptions && (
            <Autocomplete<SelectOption>
              fullWidth
              sx={{ marginRight: '10px' }}
              className={styles.moveRecipeCalculatorAutocomplete}
              value={selectedFolder}
              disabled={recipeFoldersForUserQuery.isFetching}
              noOptionsText={t('UIKit.NoResults')}
              onChange={(_, value) => handleOnFolderChanged(value)}
              options={foldersOptions}
              getOptionLabel={(label) => label.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField {...params} />}
            />
          )}
          {foldersOptions && (
            <Autocomplete<SelectOption>
              fullWidth
              sx={{ marginLeft: '10px' }}
              className={styles.moveRecipeCalculatorAutocomplete}
              value={selectedRecipe}
              disabled={recipeFoldersForUserQuery.isFetching}
              noOptionsText={t('UIKit.NoResults')}
              onChange={(_, value) => setSelectedRecipe(value)}
              options={recipeOptions}
              getOptionLabel={(label) => label.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField {...params} />}
            />
          )}
        </Stack>
        <FormControlLabel
          className={styles.moveRecipeCalculatorCheckbox}
          control={
            <Checkbox
              checked={shouldBeCopied}
              onChange={() => setShouldBeCopied(!shouldBeCopied)}
            />
          }
          label={t('MoveRecipeCalculatorDialog.ShouldBeCopied')}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', margin: '0 10px 0 10px' }}>
        <Button
          textColor={'#000'}
          onClick={onClose}
          disabled={recipeFoldersForUserQuery.isFetching}
        >
          {t('UIKit.Cancel')}
        </Button>
        <Button
          onClick={handleMoveRecipe}
          disabled={!selectedRecipe || recipeFoldersForUserQuery.isFetching}
        >
          {t(shouldBeCopied ? 'UIKit.Copy' : 'UIKit.Move')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
