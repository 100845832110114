import { FC } from 'react';

import { InvestigationEditor } from '@/pages/authorized/recipe/investigation-editor/InvestigationEditor';

import styles from './PrintRecipeDialog.module.scss';

type Props = {
  recipeName: string;
  recipeDescription: string;
  text: string | null;
};
export const PrintInvestigation: FC<Props> = ({ recipeName, recipeDescription, text }) => {
  return (
    <>
      <div className={styles.pageBreak} />
      <div className={styles.printRecipeTitle}>{recipeName}</div>
      <div className={styles.printRecipeDescription}>{recipeDescription}</div>
      <InvestigationEditor
        value={text}
        onChange={() => {}}
        onSave={() => {}}
        saveIsDisabled={true}
        isReadonly={true}
        isPrintViewEnabled={true}
      />
    </>
  );
};
