import { useScopedTranslation } from '@/application/localization/useScopedTranslation';
import { FormulaViewType } from '@/services/api/api-client';
import { Stack } from '@mui/material';
import React, { FC } from 'react';

import styles from './SegerFormulaItem.module.scss';

type SegerFormulaItemProps = {
  oxide: string;
  value: number;
  viewType: FormulaViewType;
};

export const SegerFormulaItem: FC<SegerFormulaItemProps> = ({ viewType, oxide, value }) => {
  const { t } = useScopedTranslation('Oxide');
  return (
    <Stack spacing={1} direction={'row'} flexWrap={'nowrap'} className={styles.segerFormulaItem}>
      <div className={styles.segerFormulaItemOxide}>{t(oxide)}</div>
      <div className={styles.segerFormulaItemValue}>
        {viewType === FormulaViewType.PercentsMolar ? `${value.toFixed(3)}%` : value.toFixed(3)}
      </div>
    </Stack>
  );
};
