import FormatAlignCenterIcon from '@/assets/icons/investigation-editor/alignCenter.svg?react';
import FormatAlignLeftIcon from '@/assets/icons/investigation-editor/alignLeft.svg?react';
import FormatAlignRightIcon from '@/assets/icons/investigation-editor/alignRight.svg?react';
import BorderColorIcon from '@/assets/icons/investigation-editor/backgroundColor.svg?react';
import BlockQuote from '@/assets/icons/investigation-editor/blockquote.svg?react';
import FormatBoldIcon from '@/assets/icons/investigation-editor/bold.svg?react';
import FormatHeading from '@/assets/icons/investigation-editor/heading.svg?react';
import HorizontalRuleIcon from '@/assets/icons/investigation-editor/horizontalLine.svg?react';
import InsertImageIcon from '@/assets/icons/investigation-editor/insertImage.svg?react';
import AddLinkIcon from '@/assets/icons/investigation-editor/insertLink.svg?react';
import BackupTableIcon from '@/assets/icons/investigation-editor/insertTable.svg?react';
import FormatItalicIcon from '@/assets/icons/investigation-editor/italic.svg?react';
import OrderedList from '@/assets/icons/investigation-editor/orderedList.svg?react';
import StrikethroughSIcon from '@/assets/icons/investigation-editor/strikeThrough.svg?react';
import FormatSubheading from '@/assets/icons/investigation-editor/subheading.svg?react';
import SubscriptIcon from '@/assets/icons/investigation-editor/subscript.svg?react';
import SuperscriptIcon from '@/assets/icons/investigation-editor/superscript.svg?react';
import CalculateIcon from '@/assets/icons/investigation-editor/trello.svg?react';
import FormatUnderlinedIcon from '@/assets/icons/investigation-editor/underscore.svg?react';
import UnorderedList from '@/assets/icons/investigation-editor/unorderedList.svg?react';
import SaveIcon from '@/assets/icons/save.svg?react';
import { Dialog } from '@/components/uikit/Dialog/Dialog';
import { ToggleButton } from '@/components/uikit/ToggleButton/ToggleButton';
import { ToggleButtonGroup } from '@/components/uikit/ToggleButtonGroup/ToggleButtonGroup';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
  HeadingTagType,
} from '@lexical/rich-text';
import {
  $getSelectionStyleValueForProperty,
  $patchStyleText,
  $setBlocksType,
} from '@lexical/selection';
import { INSERT_TABLE_COMMAND } from '@lexical/table';
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { Stack } from '@mui/material';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  DEPRECATED_$isGridSelection,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import * as React from 'react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { useRecipeParam } from '../../../../../../hooks/recipe/useRecipeParam';
import { INSERT_HORIZONTAL_RULE_COMMAND } from '../../nodes/horizontal-rule/HorizontalRootNode';
import { getSelectedNode } from '../../utils/getSelectedNode';
import { sanitizeUrl } from '../../utils/url';
import { INSERT_CALCULATION_COMMAND } from '../CalculationPlugin';
import { useAddPhotoToInvestigation } from '../drag-drop-paste-plugin/hooks';

import { AddCalculationDialog } from './add-calculation-dialog/AddCalculationDialog';
import { AddLinkDialog } from './add-link-dialog/AddLinkDialog';
import { AddTableDialog } from './add-table-dialog/AddTableDialog';
import styles from './ToolbarPlugin.module.scss';

const defaultTextBackgroundColor = 'rgba(255,255,255,0)';
const selectedTextBackgroundColor = 'rgba(255,219,0,0.76)';

type Props = {
  onSave: () => void;
  saveIsDisabled: boolean;
  isReadonly: boolean;
};

export const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  h1: 'Heading 1',
  h2: 'Heading 2',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
};

export const ToolbarPlugin: FC<Props> = ({ onSave, saveIsDisabled, isReadonly }) => {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>('paragraph');
  const [bgColor, setBgColor] = useState<string>(defaultTextBackgroundColor);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isSubscript, setIsSubscript] = useState(false);
  const [isSuperscript, setIsSuperscript] = useState(false);
  const [addTableOpened, setAddTableOpened] = useState(false);
  const [addLinkOpened, setAddLinkOpened] = useState(false);
  const [addCalculationOpened, setAddCalculationOpened] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { recipeId } = useRecipeParam();

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsSubscript(selection.hasFormat('subscript'));
      setIsSuperscript(selection.hasFormat('superscript'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
          const type = parentList ? parentList.getListType() : element.getListType();
          setBlockType(type as keyof typeof blockTypeToBlockName);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
        }
      }
      // Handle buttons
      setBgColor(
        $getSelectionStyleValueForProperty(
          selection,
          'background-color',
          defaultTextBackgroundColor,
        ),
      );
    }
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
    );
  }, [activeEditor, editor, updateToolbar]);

  const applyStyleText = useCallback(
    (styles: Record<string, string>) => {
      activeEditor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, styles);
        }
      });
    },
    [activeEditor],
  );

  const onBgColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ 'background-color': value });
    },
    [applyStyleText],
  );

  const handleInsertLink = useCallback(
    (link: string) => {
      if (!isLink) {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(link));
      }
    },
    [editor, isLink],
  );

  const handleInsertCalculation = useCallback(
    (recipeId: number, calculationId: number, versionId: number) => {
      if (!isLink) {
        activeEditor.dispatchCommand(INSERT_CALCULATION_COMMAND, {
          recipeId: recipeId,
          calculationId: calculationId,
          versionId: versionId,
        });
      }
    },
    [editor, isLink],
  );

  const handleLinkButtonClick = useCallback(() => {
    if (!isLink) {
      setAddLinkOpened(true);
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const handleInsertTable = (columnsCount: number, rowsCount: number) => {
    activeEditor.dispatchCommand(INSERT_TABLE_COMMAND, {
      columns: columnsCount.toString(),
      rows: rowsCount.toString(),
      includeHeaders: false,
    });
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode(headingSize));
        }
      });
    } else {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
          $setBlocksType(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatUnorderedList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };
  const formatOrderedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };
  const formatBlockQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
          $setBlocksType(selection, () => $createQuoteNode());
        }
      });
    }
  };

  const handleFileInputClick = () => {
    hiddenFileInput?.current?.click();
  };

  const addPhotoMutation = useAddPhotoToInvestigation(recipeId, editor);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target) {
      const files = event.target.files;
      if (files !== null) {
        addPhotoMutation.mutate(files[0]);
      }
      event.target.value = '';
      event.target.files = null;
    }
  };

  const handleAddCalculationClick = () => {
    setAddCalculationOpened(true);
  };

  return (
    <div className={styles.toolbarContainer}>
      {
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack
            className={styles.toolbarButtonsPanel}
            direction={'row'}
            spacing={3}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <ToggleButtonGroup size='small' disabled={isReadonly} variant='text'>
              <ToggleButton
                className={styles.toggleButton}
                selected={blockType === 'h1'}
                onClick={() => {
                  formatHeading('h1');
                }}
                value='h1'
              >
                <FormatHeading />
              </ToggleButton>
              <ToggleButton
                className={styles.toggleButton}
                selected={blockType === 'h2'}
                onClick={() => {
                  formatHeading('h2');
                }}
                value='h2'
              >
                <FormatSubheading />
              </ToggleButton>
              <ToggleButton
                className={styles.toggleButton}
                selected={isBold}
                onClick={() => {
                  activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                }}
                value='bold'
              >
                <FormatBoldIcon />
              </ToggleButton>
              <ToggleButton
                className={styles.toggleButton}
                selected={isItalic}
                onClick={() => {
                  activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                }}
                value='italic'
              >
                <FormatItalicIcon />
              </ToggleButton>
              <ToggleButton
                className={styles.toggleButton}
                selected={isUnderline}
                onClick={() => {
                  activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
                }}
                value='underlined'
              >
                <FormatUnderlinedIcon />
              </ToggleButton>
              <ToggleButton
                className={styles.toggleButton}
                selected={isStrikethrough}
                value='strikethrough'
                onClick={() => {
                  activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
                }}
              >
                <StrikethroughSIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup size='small' disabled={isReadonly} variant='text'>
              <ToggleButton
                className={styles.toggleButton}
                selected={isSubscript}
                value='subscript'
                onClick={() => {
                  if (isSuperscript) {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'superscript');
                  }
                  activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript');
                }}
              >
                <SubscriptIcon />
              </ToggleButton>
              <ToggleButton
                className={styles.toggleButton}
                selected={isSuperscript}
                value='superscript'
                onClick={() => {
                  if (isSubscript) {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript');
                  }
                  activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'superscript');
                }}
              >
                <SuperscriptIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup size='small' disabled={isReadonly} variant='text'>
              <ToggleButton
                className={styles.toggleButton}
                value='blockQuote'
                selected={blockType === 'quote'}
                onClick={formatBlockQuote}
              >
                <BlockQuote />
              </ToggleButton>
              <ToggleButton
                className={styles.toggleButton}
                value='highlight'
                selected={bgColor === selectedTextBackgroundColor}
                onClick={() => {
                  if (bgColor !== defaultTextBackgroundColor) {
                    onBgColorSelect(defaultTextBackgroundColor);
                  } else {
                    onBgColorSelect(selectedTextBackgroundColor);
                  }
                }}
              >
                <BorderColorIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup size='small' disabled={isReadonly} variant='text'>
              <ToggleButton
                className={styles.toggleButton}
                value='horizontal-divider'
                onClick={() => {
                  activeEditor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined);
                }}
              >
                <HorizontalRuleIcon />
              </ToggleButton>
              <ToggleButton
                className={styles.toggleButton}
                value='insert-table'
                onClick={() => setAddTableOpened(true)}
              >
                <BackupTableIcon />
              </ToggleButton>
              <ToggleButton
                className={styles.toggleButton}
                selected={isLink}
                value='insert-link'
                onClick={handleLinkButtonClick}
              >
                <AddLinkIcon />
              </ToggleButton>
              <ToggleButton
                className={styles.toggleButton}
                value='insert-image'
                onClick={handleFileInputClick}
              >
                <InsertImageIcon />
              </ToggleButton>
              <input
                className={styles.toolbarInputImage}
                type='file'
                ref={hiddenFileInput}
                onChangeCapture={handleFileChange}
                accept='image/*'
              />
              <ToggleButton
                className={styles.toggleButton}
                value='insert-calculation'
                onClick={handleAddCalculationClick}
              >
                <CalculateIcon />
              </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup size='small' disabled={isReadonly} variant='text'>
              <ToggleButton
                className={styles.toggleButton}
                selected={blockType === 'bullet'}
                value='left'
                onClick={formatUnorderedList}
              >
                <UnorderedList />
              </ToggleButton>
              <ToggleButton
                className={styles.toggleButton}
                selected={blockType === 'number'}
                value='left'
                onClick={formatOrderedList}
              >
                <OrderedList />
              </ToggleButton>
              <ToggleButton
                className={styles.toggleButton}
                value='left'
                onClick={() => {
                  activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
                }}
              >
                <FormatAlignLeftIcon />
              </ToggleButton>
              <ToggleButton
                className={styles.toggleButton}
                value='center'
                onClick={() => {
                  activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
                }}
              >
                <FormatAlignCenterIcon />
              </ToggleButton>
              <ToggleButton
                className={styles.toggleButton}
                value='right'
                onClick={() => {
                  activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
                }}
              >
                <FormatAlignRightIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          <ToggleButtonGroup size='small' disabled={isReadonly} variant='text'>
            <ToggleButton
              className={styles.toggleButton}
              disabled={saveIsDisabled}
              onClick={onSave}
              value='save'
            >
              <SaveIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      }
      <Dialog maxWidth={'md'} open={addTableOpened} onClose={() => setAddTableOpened(false)}>
        <AddTableDialog onSubmit={handleInsertTable} onClose={() => setAddTableOpened(false)} />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={addLinkOpened}
        onClose={() => setAddLinkOpened(false)}
      >
        <AddLinkDialog onSubmit={handleInsertLink} onClose={() => setAddLinkOpened(false)} />
      </Dialog>
      <Dialog
        maxWidth={'md'}
        open={addCalculationOpened}
        onClose={() => setAddCalculationOpened(false)}
      >
        <AddCalculationDialog
          onSubmit={handleInsertCalculation}
          onClose={() => setAddCalculationOpened(false)}
        />
      </Dialog>
    </div>
  );
};
