import { getRecipeRoute } from '@/application/constants/links';
import ArrowInCircleIcon from '@/assets/icons/arrow-down-circle.svg?react';
import BookOpenedIcon from '@/assets/icons/book-open.svg?react';
import { Autocomplete } from '@/components/uikit/Autocomplete/Autocomplete';
import { IconButton } from '@/components/uikit/IconButton/IconButton';
import { TextField } from '@/components/uikit/TextField/TextField';
import { openInNewTab } from '@/helpers/window-helper';
import { CalculationTemplateDto, IngredientDto } from '@/services/api/api-client.types';
import { useGetCalculationTemplatesQuery } from '@/services/api/api-client/CalculationTemplatesQuery';
import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './TemplateSelector.module.scss';

export type CalculationTemplateOption = Pick<CalculationTemplateDto, 'name' | 'type' | 'recipeId'>;

type Props = {
  loadTemplate: (template: IngredientDto[], templateName: string) => void;
};

export const TemplateSelector: FC<Props> = ({ loadTemplate }) => {
  const { t } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState<CalculationTemplateOption | null>(null);
  const calculationTemplatesQuery = useGetCalculationTemplatesQuery({
    refetchOnMount: false,
  });

  const handleOpenRecipeWithTemplateClick = () => {
    if (selectedTemplate !== null) {
      const url = `${window.location.origin}${getRecipeRoute({
        recipeId: selectedTemplate?.recipeId,
      })}`;
      openInNewTab(url);
    }
  };

  const handleLoadTemplateClick = () => {
    if (selectedTemplate !== null) {
      const template = calculationTemplatesQuery?.data?.find(
        (x) => x.recipeId === selectedTemplate.recipeId,
      );
      if (template) {
        loadTemplate(template.recipe, template.name);
      }
    }
  };

  const calculationTemplatesOptions = useMemo(
    () =>
      calculationTemplatesQuery.data?.map(
        (x): CalculationTemplateOption => ({
          name: x.name,
          type: x.type,
          recipeId: x.recipeId,
        }),
      ) ?? [],
    [calculationTemplatesQuery.data],
  );

  return (
    <>
      <div className={styles.headerContainer}>
        <Typography className={styles.calculationName} variant='body2' noWrap>
          {t('Page.Recipe.DefaultCalculationName')}
        </Typography>
        <Typography className={styles.title} variant='body2' noWrap>
          {t('TemplateSelector.Title')}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Autocomplete<CalculationTemplateOption>
              clearOnBlur
              blurOnSelect
              value={selectedTemplate}
              options={calculationTemplatesOptions}
              noOptionsText={t('UIKit.NoResults')}
              className={styles.autocomplete}
              onChange={(_, value) => setSelectedTemplate(value)}
              getOptionLabel={(label) => label.name}
              renderInput={(params) => <TextField {...params} />}
              isOptionEqualToValue={(option, value) => option.recipeId === value.recipeId}
              groupBy={(x) => x.type}
            />
          </Grid>
          <Grid item xs={2}>
            <Stack direction={'row'}>
              <Tooltip title={t('TemplateSelector.Tooltip.OpenRecipe')}>
                <IconButton onClick={handleOpenRecipeWithTemplateClick}>
                  <BookOpenedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('TemplateSelector.Tooltip.LoadTemplate')}>
                <IconButton onClick={handleLoadTemplateClick}>
                  <ArrowInCircleIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
      </div>
      <Typography className={styles.subtitle} variant='body2' noWrap>
        {t('TemplateSelector.Subtitle')}
      </Typography>
    </>
  );
};
