import AttachmentIcon from '@/assets/icons/attachment.svg?react';
import CopyIcon from '@/assets/icons/copy.svg?react';
import MoveIcon from '@/assets/icons/corner-up-left.svg?react';
import LockIcon from '@/assets/icons/lock.svg?react';
import ResetIcon from '@/assets/icons/refresh.svg?react';
import SaveIcon from '@/assets/icons/save.svg?react';
import RemoveIcon from '@/assets/icons/trash.svg?react';
import UnlockIcon from '@/assets/icons/unlock.svg?react';
import { RemoveDialog } from '@/components/remove-dialog/RemoveDialog';
import { StullChart } from '@/components/stull-chart/StullChart';
import { Button } from '@/components/uikit/Button/Button';
import { Divider } from '@/components/uikit/Divider/Divider';
import { IconButton } from '@/components/uikit/IconButton/IconButton';
import { LinearProgress } from '@/components/uikit/LinearProgress/LinearProgress';
import { TextField } from '@/components/uikit/TextField/TextField';
import { Tooltip } from '@/components/uikit/Tooltip/Tooltip';
import useCtrlS from '@/helpers/ctrlSHook';
import { localFormat } from '@/helpers/date-helpers';
import { requiredRule } from '@/helpers/form/react-hook-form-helper';
import {
  CalculationHistoryRecordDto,
  FormulaViewType,
  IngredientDto,
  MaterialQuery,
  RecipeCalculationDto,
} from '@/services/api/api-client';
import { Box, Grid, Stack, Switch } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { ElementFormatType } from 'lexical';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRecipeCalculations } from '../../../../../common/hooks/recipe/useRecipeCalculations';

import {
  INGREDIENTS_GRID_MATERIAL,
  INGREDIENTS_GRID_REMOVE,
  INGREDIENTS_GRID_TOTAL,
  INGREDIENTS_GRID_VALUE,
} from './constants';
import { buildIngredientFields, emptyIngredientField, preventEnterKeySubmission } from './helpers';
import {
  getOnTheFlyCalculationQueryKey,
  useCalculationFormDefaultValues,
  useCalculationFormValues,
  useCopyRecipeCalculatorMutation,
  useIngredientDtos,
  useIngredientsFields,
  useOnTheFlyCalculationQuery,
  useRecipeCalculatorForm,
  useReduceTo100Mutation,
  useRemoveRecipeCalculationMutation,
} from './hooks';
import { MaterialOption } from './ingredient-fields/IngredientField';
import { IngredientFields } from './ingredient-fields/IngredientFields';
import { MoveRecipeCalculatorDialog } from './move-recipe-calculator-dialog/MoveRecipeCalculatorDialog';
import styles from './RecipeCalculator.module.scss';
import { SegerFormula } from './seger-formula/SegerFormula';
import { TemplateSelector } from './template-selector/TemplateSelector';

export type IngredientFormField = {
  material: MaterialOption | undefined;
  value: string;
};

export type RecipeCalculationForm = {
  name: string;
  viewType: FormulaViewType;
  isLocked: boolean;
  mainIngredients: IngredientFormField[];
  additionalIngredients: IngredientFormField[];
  includeAdditionsIntoCalculations: boolean;
};

type RecipeCalculatorProps = {
  className?: string;
  recipeId: number;
  calculation: RecipeCalculationDto;
  history: CalculationHistoryRecordDto;
  isReadonly: boolean;
  isSelectable: boolean;
  format?: ElementFormatType | null;
  showStullChart?: boolean;
  // historyRecords: CalculationHistoryRecordHeaderDto[];
  // setSelectedVersion: (version: CalculationHistoryRecordHeaderDto) => void;
  // selectedVersion: CalculationHistoryRecordHeaderDto;
};

export const RecipeCalculator: FC<RecipeCalculatorProps> = ({
  className,
  recipeId,
  calculation,
  history,
  isReadonly,
  isSelectable,
  format,
  showStullChart,
  // historyRecords,
  // setSelectedVersion,
  // selectedVersion,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { selectedCalculation, changeSelectedCalculator, pushStullPoint, changeCalculationDirty } =
    useRecipeCalculations();
  const recipeCalculatorSelected = selectedCalculation?.id === calculation.id;

  const materialsQuery = MaterialQuery.useSearchQuery(undefined, { refetchOnMount: false });

  const [removeOpened, setRemoveOpened] = useState(false);
  const [moveRecipeCalculatorDialogOpened, setMoveRecipeCalculatorDialogOpened] = useState(false);
  const removeRecipeCalculationMutation = useRemoveRecipeCalculationMutation();

  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const isVersionsMenuOpened = Boolean(anchorEl);

  const defaultValues = useCalculationFormDefaultValues(calculation, history);
  const form = useRecipeCalculatorForm(recipeId, calculation.id, defaultValues);

  // Needed to populate form with default values.
  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const formValues = useCalculationFormValues(form.control);
  const mainIngredientsFields = useIngredientsFields(form.control, 'mainIngredients');
  const additionalIngredientsFields = useIngredientsFields(form.control, 'additionalIngredients');

  const isFormValid = !form.formState.isValidating && form.formState.isValid;
  const dtos = useIngredientDtos(formValues.mainIngredients, formValues.additionalIngredients);
  const onTheFlyCalculationQuery = useOnTheFlyCalculationQuery(
    dtos,
    recipeId,
    calculation.id,
    formValues.viewType,
    formValues.includeAdditionsIntoCalculations,
    isFormValid,
  );
  const reduceTo100Mutation = useReduceTo100Mutation(formValues.mainIngredients, form.setValue);
  const copyRecipeCalculationMutation = useCopyRecipeCalculatorMutation(recipeId);

  // Needed to clean up seger formula values when all ingredients were removed.
  useEffect(() => {
    if (dtos.length === 0 && onTheFlyCalculationQuery.data) {
      queryClient.setQueryData(
        getOnTheFlyCalculationQueryKey(
          dtos,
          recipeId,
          calculation.id,
          formValues.viewType,
          formValues.includeAdditionsIntoCalculations,
        ),
        () => undefined,
      );
    }
  }, [dtos]);

  // Needed to populate stull chart.
  useEffect(() => {
    if (onTheFlyCalculationQuery.data && !calculation.isDeleted) {
      pushStullPoint({
        ...onTheFlyCalculationQuery.data.stullChartPoint,
        recipeCalculationId: calculation.id,
        recipeCalculationName: formValues.name,
        recipeId: recipeId,
      });
    }
  }, [onTheFlyCalculationQuery.data, formValues.name]);

  useEffect(() => {
    changeCalculationDirty({ [calculation.id]: form.formState.isDirty });
  }, [form.formState.isDirty]);

  // Needed to add empty field on form blur.
  const handleOnFormBlur = useCallback(() => {
    const addIngredientFieldIfNeeded = (values: IngredientFormField[], add: () => void) => {
      const lastField = values[values.length - 1];
      if (lastField?.material) {
        add();
      }
    };

    addIngredientFieldIfNeeded(form.getValues('mainIngredients'), mainIngredientsFields.add);
    addIngredientFieldIfNeeded(
      form.getValues('additionalIngredients'),
      additionalIngredientsFields.add,
    );
  }, [additionalIngredientsFields.add, form, mainIngredientsFields.add]);

  // Needed to focus selected calculator.
  const handleOnCalculatorClick = useCallback(() => {
    if (isSelectable && !recipeCalculatorSelected) {
      changeSelectedCalculator({
        id: calculation.id,
        isReadonly: isReadonly,
      });
    } else {
      // TODO decide what to do with unfocus
      //dispatch(recipeCalculatorActions.resetSelectedCalculatorId());
    }
  }, [recipeCalculatorSelected, calculation.id]);

  const handleOnRemoveConfirmed = useCallback(() => {
    removeRecipeCalculationMutation.mutate(
      { recipeId, calculationId: calculation.id },
      {
        onSuccess: () => setRemoveOpened(false),
      },
    );
  }, [recipeId, calculation.id]);

  const handleLockButtonClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    const isLockedCurrentState = formValues.isLocked;
    form.setValue('isLocked', !isLockedCurrentState, { shouldDirty: true });
    form.handleSubmitDefault(event);
  };

  const handleOnCopyClick = useCallback(() => {
    copyRecipeCalculationMutation.mutate({
      ingredients: dtos,
      name: formValues.name,
      viewType: formValues.viewType,
      isLocked: formValues.isLocked,
      includeAdditionsIntoCalculations: formValues.includeAdditionsIntoCalculations,
    });
  }, [
    dtos,
    formValues.name,
    formValues.viewType,
    formValues.includeAdditionsIntoCalculations,
    formValues.isLocked,
  ]);

  const loadTemplate = (template: IngredientDto[], templateName: string) => {
    form.setValue(
      'mainIngredients',
      [...buildIngredientFields(template, false), emptyIngredientField],
      { shouldDirty: true },
    );
    form.setValue(
      'additionalIngredients',
      [...buildIngredientFields(template, true), emptyIngredientField],
      { shouldDirty: true },
    );
    form.setValue('name', templateName, { shouldDirty: true });
  };

  // const handleVersionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleVersionsMenuClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleVersionMenuItemClick = (option: CalculationHistoryRecordHeaderDto) => () => {
  //   setSelectedVersion(option);
  //   handleVersionsMenuClose();
  // };

  const loading =
    materialsQuery.isLoading ||
    onTheFlyCalculationQuery.isFetching ||
    reduceTo100Mutation.isLoading ||
    form.formState.isSubmitting;

  const containsAnyAdditionalIngredients =
    additionalIngredientsFields.fields.filter((field) => field.material != null).length > 0;
  const containsAnyMainIngredients =
    mainIngredientsFields.fields.filter((field) => field.material != null).length > 0;

  const isEmpty = !containsAnyAdditionalIngredients && !containsAnyMainIngredients;

  useCtrlS(() => {
    if (!isReadonly && !loading && form.formState.isDirty) {
      form.handleSubmitDefault();
    }
  });

  const renderCalculationName = () => (
    <TextField
      {...form.register('name', { ...requiredRule() })}
      fullWidth
      sx={{ mb: 2 }}
      variant={'standard'}
      // Needed to prevent unnecessary on-the-fly calculation for name field.
      onBlur={(e) => e.preventDefault()}
      InputProps={{ sx: { fontWeight: 600 } }}
      disabled={formValues.isLocked || isReadonly}
    />
  );

  const renderCalculationHeader = () => {
    if (isEmpty) {
      return null;
    }
    if (showStullChart) {
      return (
        <Stack direction={'row'} spacing={2}>
          {onTheFlyCalculationQuery.data && (
            <StullChart
              recipeCalculationId={selectedCalculation?.id}
              className={styles.calculatorStullChart}
              showMinimized
              stullPoints={[
                {
                  ...onTheFlyCalculationQuery.data.stullChartPoint,
                  recipeCalculationId: calculation.id,
                  recipeCalculationName: formValues.name,
                  recipeId: recipeId,
                },
              ]}
            />
          )}
          <Stack
            direction={'column'}
            justifyContent={'start'}
            alignContent={'start'}
            alignItems={'start'}
          >
            {renderCalculationName()}
            {showStullChart && (
              <div
                className={clsx(
                  styles.calculatorCreatedAtLabel,
                  styles.calculatorCreatedAtLabelUnderHeader,
                )}
              >
                {localFormat(calculation.createdAt, 'Pp')}
              </div>
            )}
          </Stack>
        </Stack>
      );
    } else {
      return (
        <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
          {renderCalculationName()}
          {!isReadonly && (
            <Tooltip
              title={
                formValues.isLocked
                  ? t('RecipeCalculator.LockButtonTootip')
                  : t('RecipeCalculator.UnlockButtonTootip')
              }
            >
              <IconButton disabled={loading} onClick={handleLockButtonClicked}>
                {formValues.isLocked ? <LockIcon /> : <UnlockIcon />}
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      );
    }
  };

  return (
    <Box
      onClick={handleOnCalculatorClick}
      className={clsx(
        styles.calculator,
        { [styles.cursor]: isSelectable },
        { [styles.nonSelectable]: !isSelectable },
        { [styles.active]: recipeCalculatorSelected },
        { [styles.leftAligned]: format === 'left' || format === 'start' },
        { [styles.rightAligned]: format === 'right' || format === 'end' },
        { [styles.centerAligned]: format === 'center' || format === 'justify' },
        className,
      )}
    >
      {loading && <LinearProgress isAbsolute />}
      <FormProvider {...form}>
        <form
          className={styles.calculatorForm}
          noValidate={true}
          onBlur={handleOnFormBlur}
          onSubmit={form.handleSubmitDefault}
          name={`recipe-calculator-${calculation.id}`}
          onKeyPress={preventEnterKeySubmission}
        >
          {renderCalculationHeader()}
          {!isEmpty ? (
            <SegerFormula
              viewType={formValues.viewType}
              calculation={onTheFlyCalculationQuery.data}
              disabled={formValues.isLocked || isReadonly}
            />
          ) : (
            <TemplateSelector loadTemplate={loadTemplate} />
          )}
          <Grid container spacing={1} columns={INGREDIENTS_GRID_TOTAL} sx={{ mt: 1 }}>
            <IngredientFields
              materials={materialsQuery.data}
              name={'mainIngredients'}
              {...mainIngredientsFields}
              disabled={formValues.isLocked || isReadonly}
              isCalculationEmpty={isEmpty}
            />
            <Grid item xs={INGREDIENTS_GRID_REMOVE} />
            {!isEmpty && (
              <Grid item xs={INGREDIENTS_GRID_MATERIAL}>
                <Stack direction={'row'} alignItems={'center'} spacing={1} justifyContent={'end'}>
                  {!isReadonly && (
                    <Button
                      size={'small'}
                      color={'primary'}
                      variant={'outlined'}
                      disabled={!isFormValid || formValues.isLocked}
                      onClick={() => reduceTo100Mutation.mutate(dtos)}
                    >
                      {t('RecipeCalculator.ReduceTo100')}
                    </Button>
                  )}
                  <span>{t('RecipeCalculator.Total')}</span>
                </Stack>
              </Grid>
            )}
            {!isEmpty && (
              <Grid item xs={INGREDIENTS_GRID_VALUE} alignSelf={'center'}>
                <div className={styles.calculatorFormTotalValue}>
                  {onTheFlyCalculationQuery.data?.ingredientsOverallSum}
                </div>
              </Grid>
            )}
            {(containsAnyAdditionalIngredients || !isReadonly) && !isEmpty && (
              <Grid item xs={INGREDIENTS_GRID_TOTAL}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <Stack direction={'row'} spacing={0.5}>
                    <AttachmentIcon className={styles.calculatorFormAdditionsIcon} />
                    <div className={styles.calculatorFormAdditionsTitle}>
                      {t('RecipeCalculator.Additions')}
                    </div>
                  </Stack>
                  {!isReadonly && (
                    <Controller
                      name={'includeAdditionsIntoCalculations'}
                      render={({ field }) => (
                        <Tooltip title={t('RecipeCalculator.IncludeAdditionsIntoCalculations')}>
                          <Switch
                            size={'small'}
                            disabled={formValues.isLocked || isReadonly}
                            checked={field.value}
                            onChange={field.onChange}
                          />
                        </Tooltip>
                      )}
                    />
                  )}
                </Stack>
                <Divider sx={{ mt: '4px' }} />
              </Grid>
            )}
            {!isEmpty && (
              <IngredientFields
                materials={materialsQuery.data}
                name={'additionalIngredients'}
                {...additionalIngredientsFields}
                disabled={formValues.isLocked || isReadonly}
                isCalculationEmpty={isEmpty}
              />
            )}
          </Grid>
          {!isReadonly && (
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ mt: 1 }}>
              <Stack direction={'row'}>
                <Tooltip title={t('UIKit.Remove')}>
                  <IconButton
                    disabled={formValues.isLocked || loading}
                    onClick={() => setRemoveOpened(true)}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Tooltip>
                {!isEmpty && (
                  <Tooltip title={t('UIKit.Reset')}>
                    <IconButton
                      disabled={loading || !form.formState.isDirty}
                      onClick={() => form.reset(defaultValues)}
                    >
                      <ResetIcon className={styles.icon} />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
              {!isEmpty && (
                <Stack direction={'row'}>
                  <Tooltip
                    title={
                      form.formState.isDirty ? t('RecipeCalculator.MoveDisabled') : t('UIKit.Move')
                    }
                  >
                    <IconButton
                      disabled={loading || form.formState.isDirty || !isFormValid}
                      onClick={() => setMoveRecipeCalculatorDialogOpened(true)}
                    >
                      <MoveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('UIKit.Copy')}>
                    <IconButton disabled={loading || !isFormValid} onClick={handleOnCopyClick}>
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('UIKit.Save')}>
                    <IconButton
                      type={'submit'}
                      disabled={loading || !form.formState.isDirty || !isFormValid}
                    >
                      <SaveIcon color={'#2F80ED'} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}
            </Stack>
          )}
          {!showStullChart && !isEmpty && (
            <div
              className={clsx(
                styles.calculatorCreatedAtLabel,
                styles.calculatorCreatedAtLabelInFooter,
              )}
              // onClick={handleVersionsMenuClick}
            >
              {localFormat(calculation.createdAt, 'Pp')}
            </div>
          )}
        </form>
      </FormProvider>
      <RemoveDialog
        isOpened={removeOpened}
        removeTitle={t('RemoveDialog.Title', { name: calculation.name })}
        onRemove={handleOnRemoveConfirmed}
        onClose={() => setRemoveOpened(false)}
      />
      <MoveRecipeCalculatorDialog
        isOpened={moveRecipeCalculatorDialogOpened}
        onClose={() => setMoveRecipeCalculatorDialogOpened(false)}
        recipeCalculatorName={calculation.name}
        recipeId={recipeId}
        recipeCalculationId={calculation.id}
      />
      {/* <Menu
        anchorEl={anchorEl}
        open={isVersionsMenuOpened}
        onClose={handleVersionsMenuClose}
        PaperProps={{
          style: {
            maxHeight: '100px',
            width: '20ch',
          },
        }}
      >
        {historyRecords.map((option) => (
          <MenuItem
            key={option.id}
            dense
            selected={option.id === selectedVersion.id}
            onClick={handleVersionMenuItemClick(option)}
          >
            {localFormat(option.createdAt, 'Pp')}
          </MenuItem>
        ))}
      </Menu> */}
    </Box>
  );
};
