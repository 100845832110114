import * as React from 'react';
import { FieldValues, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { NavigateFunction } from 'react-router';

import { useErrorHandler } from './useErrorHandler';

type AdvancedFormReturnType<
  TFieldValues extends FieldValues = FieldValues,
  // ignored because it's used in react-hook-form as well
  // eslint-disable-next-line @typescript-eslint/ban-types
  TContext extends object = object,
> = UseFormReturn<TFieldValues, TContext> & {
  overallError: string;
  formErrorCombined: string;
  handleSubmitDefault: (e?: React.BaseSyntheticEvent) => Promise<void>;
};

/*
 * useAdvancedForm adds some advanced options on top of useForm (react-hook-form).
 * These advanced functions include:
 * 1. Ability to specify submitHandler when defining the form
 * 2. Parse error messages returned from backend and assign them to proper fields (assuming that server-side complies to ProblemDetails RFC)
 * 3. Ability to provide defaultValues 'asynchronously' (i.e. after form has been initially rendered, e.g. when getting the data via react-query hooks).
 */
export function useAdvancedForm<
  TFieldValues extends FieldValues = FieldValues,
  // ignored because it's used in react-hook-form as well
  // eslint-disable-next-line @typescript-eslint/ban-types
  TContext extends object = object,
>(
  submitHandler: (data: TFieldValues, navigate: NavigateFunction) => Promise<void>,
  options?: {
    shouldResetOnSuccess?: boolean;
    initialize?: (form: UseFormReturn<TFieldValues, TContext>) => void;
  } & UseFormProps<TFieldValues, TContext>,
): AdvancedFormReturnType<TFieldValues, TContext> {
  const form = useForm<TFieldValues, TContext>(options);
  const shouldResetOnSuccess = !!options?.shouldResetOnSuccess;
  const handler = useErrorHandler<TFieldValues>(
    submitHandler,
    form.setError,
    shouldResetOnSuccess ? (form.reset as any) : undefined,
  );
  options?.initialize?.(form);

  return {
    ...form,
    overallError: handler.overallServerError,
    formErrorCombined: handler.serverErrorsCombined,
    handleSubmitDefault: form.handleSubmit(async (values: TFieldValues) => {
      return await handler.handler(values as any);
    }),
  };
}
