import { FileParameter } from '@/services/api/api-client';

export const createFileParameter = (file: File | null): FileParameter | null => {
  return file
    ? {
        data: file,
        fileName: file.name,
      }
    : null;
};
