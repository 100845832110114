import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';
import { FC, lazy } from 'react';

const UnauthorizedRootPageDefaultView = lazy(
  () => import('./default-view/UnauthorizedRootPageDefaultView'),
);
const UnauthorizedRootPageMobileView = lazy(
  () => import('./mobile-view/UnauthorizedRootPageMobileView'),
);

export const UnauthorizedRootPage: FC = () => {
  const isMobile = useMobileMediaQuery();
  return isMobile ? <UnauthorizedRootPageMobileView /> : <UnauthorizedRootPageDefaultView />;
};
