import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';
import { FC, lazy } from 'react';

const NotFoundPageDefaultView = lazy(() => import('./default-view/NotFoundPageDefaultView'));

const NotFoundPageMobileView = lazy(() => import('./mobile-view/NotFoundPageMobileView'));

export const NotFoundPage: FC = () => {
  const isMobile = useMobileMediaQuery();
  return isMobile ? <NotFoundPageMobileView /> : <NotFoundPageDefaultView />;
};
