import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';
import { FC, lazy } from 'react';

const PrivacyPageDefaultView = lazy(() => import('./default-view/PrivacyPageDefaultView'));
const PrivacyPageMobileView = lazy(() => import('./mobile-view/PrivacyPageMobileView'));

export const PrivacyPage: FC = () => {
  const isMobile = useMobileMediaQuery();
  return isMobile ? <PrivacyPageMobileView /> : <PrivacyPageDefaultView />;
};
