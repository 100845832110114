import { Button } from '@/components/uikit/Button/Button';
import CalculateIcon from '@mui/icons-material/Calculate';
import { DialogActions, DialogContent, DialogTitle, MenuItem, Select, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecipe } from '../../../../../../../hooks/recipe/useRecipe';
import { useRecipeCalculations } from '../../../../../../../hooks/recipe/useRecipeCalculations';
import { useRecipeParam } from '../../../../../../../hooks/recipe/useRecipeParam';

import styles from './AddCalculationDialog.module.scss';

type OwnProps = {
  onSubmit: (recipeId: number, calculationId: number, versionId: number) => void;
  onClose: () => void;
};

export const AddCalculationDialog: FC<OwnProps> = ({ onSubmit, onClose }) => {
  const { t } = useTranslation();
  const { recipeId } = useRecipeParam();
  const { isRecipeLoading } = useRecipe();
  const { calculations } = useRecipeCalculations();

  const [selectedCalculation, setSelectedCalculation] = useState<{
    calculationId: number;
    versionId: number;
  } | null>(null);

  const handleSubmit = () => {
    if (selectedCalculation !== null) {
      onSubmit(recipeId, selectedCalculation.calculationId, selectedCalculation.versionId);
    }
    onClose();
  };

  return (
    <>
      <DialogTitle>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'left'} spacing={1}>
          <CalculateIcon color={'primary'} />
          <div className={styles.addCalculationTitle}>
            {t('InvestigationEditor.Calculation.AddTitle')}
          </div>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Select
          sx={{ height: '40px' }}
          autoWidth
          disabled={isRecipeLoading}
          defaultValue={t('InvestigationEditor.Calculation.Placeholder')}
        >
          <MenuItem disabled value={t('InvestigationEditor.Calculation.Placeholder')}>
            <em>{t('InvestigationEditor.Calculation.Placeholder')}</em>
          </MenuItem>
          {calculations.map((x, index) => (
            <MenuItem
              key={index}
              value={x.name}
              onClick={() =>
                setSelectedCalculation({
                  calculationId: x.id,
                  versionId: x.historyRecordsIds[x.historyRecordsIds.length - 1].id,
                })
              }
            >
              {x.name}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', margin: '0 15px 0 15px' }}>
        <Button textColor={'#000'} onClick={onClose}>
          {t('UIKit.Cancel')}
        </Button>
        <Button onClick={handleSubmit} disabled={isRecipeLoading || selectedCalculation === null}>
          {t('UIKit.Apply')}
        </Button>
      </DialogActions>
    </>
  );
};
