import { RecipeQuery } from '@/services/api/api-client';
import constate from 'constate';

import { useRecipeParam } from './useRecipeParam';

export const [RecipeProvider, useRecipe] = constate(() => {
  const { recipeId } = useRecipeParam();

  const recipeQuery = RecipeQuery.useGetQuery(recipeId);

  return {
    recipe: recipeQuery.data,
    isRecipeLoading: recipeQuery.isLoading,
  };
});
