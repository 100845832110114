import { Button } from '@/components/uikit/Button/Button';
import { TextField } from '@/components/uikit/TextField/TextField';
import { requiredRule } from '@/helpers/form/react-hook-form-helper';
import { useAdvancedForm } from '@/helpers/form/useAdvancedForm';
import { PromocodesQuery } from '@/services/api/api-client';
import { DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { parse } from 'date-fns';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export type CreatePromocodeForm = {
  keyword: string;
  expiredAt: string;
  limitCount: string | null;
  subscriptionDaysToAdd: string;
};

type OwnProps = {
  onClose: () => void;
};

export const CreatePromocodeForm: FC<OwnProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const createPromocodeMutation = useMutation(
    (form: CreatePromocodeForm) =>
      PromocodesQuery.Client.create({
        keyWord: form.keyword,
        subscriptionDaysToAdd: parseInt(form.subscriptionDaysToAdd),
        expiredAt: parse(form.expiredAt, 'dd/MM/yyyy', new Date()),
        limitCount: !!form.limitCount ? parseInt(form.limitCount) : null,
      }),
    {
      onSuccess: () => {
        onClose();
        queryClient.invalidateQueries(PromocodesQuery.getAllQueryKey());
      },
    },
  );

  const form = useAdvancedForm<CreatePromocodeForm>(
    useCallback(async (data) => {
      await createPromocodeMutation.mutateAsync(data);
    }, []),
  );

  return (
    <>
      <DialogTitle>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'left'}>
          <Typography variant={'h2'}>{t('Promocodes.CreateDialog.Title')}</Typography>
        </Stack>
      </DialogTitle>
      <form onSubmit={form.handleSubmitDefault} onReset={onClose}>
        <DialogContent>
          <TextField
            {...form.register('keyword', { ...requiredRule() })}
            fullWidth
            placeholder={t('Promocodes.CreateDialog.Keyword')}
            error={!!form.formState.errors.keyword?.message}
            helperText={t(form.formState.errors.keyword?.message ?? ' ')}
          />
          <TextField
            {...form.register('expiredAt', { ...requiredRule() })}
            fullWidth
            placeholder={t('Promocodes.CreateDialog.ExpiredAt')}
            error={!!form.formState.errors.expiredAt?.message}
            helperText={t(form.formState.errors.expiredAt?.message ?? ' ')}
          />
          <TextField
            {...form.register('limitCount')}
            fullWidth
            placeholder={t('Promocodes.CreateDialog.LimitCount')}
            error={!!form.formState.errors.limitCount?.message}
            helperText={t(form.formState.errors.limitCount?.message ?? ' ')}
          />
          <TextField
            {...form.register('subscriptionDaysToAdd', { ...requiredRule() })}
            fullWidth
            placeholder={t('Promocodes.CreateDialog.AddDaysOfSubscription')}
            error={!!form.formState.errors.subscriptionDaysToAdd?.message}
            helperText={t(form.formState.errors.subscriptionDaysToAdd?.message ?? ' ')}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', margin: '0 15px 0 15px' }}>
          <Button textColor={'#000'} type={'reset'} disabled={form.formState.isSubmitting}>
            {t('UIKit.Cancel')}
          </Button>
          <Button type={'submit'} disabled={form.formState.isSubmitting}>
            {t('UIKit.Create')}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};
