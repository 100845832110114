import { Button } from '@/components/uikit/Button/Button';
import { TextField } from '@/components/uikit/TextField/TextField';
import { requiredRule } from '@/helpers/form/react-hook-form-helper';
import { useAdvancedForm } from '@/helpers/form/useAdvancedForm';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { ValidateResult } from 'react-hook-form/dist/types/validator';
import { useTranslation } from 'react-i18next';

import { validateUrl } from '../../../utils/url';

import styles from './AddLinkDialog.module.scss';

export type AddLinkForm = {
  link: string;
};

type OwnProps = {
  onSubmit: (link: string) => void;
  onClose: () => void;
};

export const AddLinkDialog: FC<OwnProps> = ({ onSubmit, onClose }) => {
  const { t } = useTranslation();

  const form = useAdvancedForm<AddLinkForm>(
    useCallback(async (data) => {
      onSubmit(data.link);
      onClose();
    }, []),
  );

  const handleValidate = useCallback((value: string): ValidateResult => {
    if (value && !validateUrl(value)) {
      return t('InvestigationEditor.Link.Invalid');
    }
    return true;
  }, []);

  return (
    <>
      <DialogTitle>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'left'} spacing={1}>
          <AddLinkIcon color={'primary'} />
          <div className={styles.addLinkTitle}>{t('InvestigationEditor.Link.AddTitle')}</div>
        </Stack>
      </DialogTitle>
      <form onSubmit={form.handleSubmitDefault} onReset={onClose}>
        <DialogContent>
          <TextField
            {...form.register('link', { ...requiredRule(), validate: handleValidate })}
            fullWidth
            placeholder={t('InvestigationEditor.Link.Placeholder')}
            error={!!form.formState.errors.link?.message}
            helperText={t(form.formState.errors.link?.message ?? ' ')}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', margin: '0 15px 0 15px' }}>
          <Button textColor={'#000'} type={'reset'} onClick={onClose}>
            {t('UIKit.Cancel')}
          </Button>
          <Button onClick={form.handleSubmitDefault}>{t('UIKit.Apply')}</Button>
        </DialogActions>
      </form>
    </>
  );
};
