import { MaterialDto } from '@/services/api/api-client.types';
import { useGetMySettingsQuery } from '@/services/api/api-client/UserSettingsQuery';
import { FC, useEffect, useMemo } from 'react';
import { FieldArrayPath, UseFieldArrayRemove } from 'react-hook-form';

import { useIngredientsFields } from '../hooks';
import { RecipeCalculationForm } from '../RecipeCalculator';

import { IngredientField, MaterialOption } from './IngredientField';

type IngredientFieldsProps = {
  disabled?: boolean;
  materials?: MaterialDto[];
  name: FieldArrayPath<RecipeCalculationForm>;
  remove: UseFieldArrayRemove;
  isCalculationEmpty: boolean;
} & ReturnType<typeof useIngredientsFields>;

export const IngredientFields: FC<IngredientFieldsProps> = ({
  disabled,
  materials,
  name,
  fields,
  add,
  remove,
  isCalculationEmpty,
}) => {
  const userSettings = useGetMySettingsQuery({ staleTime: 15 * 1000 });
  const inventoryMaterialsOnly = !!userSettings.data?.inventoryMaterialsOnly;

  // Needed to create the first ingredient field on component mount.
  useEffect(() => {
    if (fields.length === 0) {
      add();
    }
  }, []);

  const compareMaterialOptions = (a: MaterialOption, b: MaterialOption) => {
    if ((b.inInventory && a.inInventory) || (!b.inInventory && !a.inInventory)) {
      return a.name.localeCompare(b.name);
    }
    if (a.inInventory) {
      return -1;
    }
    if (b.inInventory) {
      return 1;
    }
    return 0;
  };

  // Creating material options for ingredient fields.
  const materialOptions = useMemo(
    () =>
      materials
        ?.filter((x) => (inventoryMaterialsOnly ? x.inInventory : true))
        .map(
          (x): MaterialOption => ({
            id: x.id,
            name: x.name,
            isPublic: x.isPublic,
            inInventory: x.inInventory,
          }),
        )
        .sort(compareMaterialOptions) ?? [],
    [materials],
  );

  return (
    <>
      {fields
        .filter((field) => (disabled ? field.material != null : field))
        .map((field, index) => (
          <IngredientField
            key={field.id}
            name={name}
            index={index}
            onRemove={remove}
            disabled={disabled}
            isCalculationEmpty={isCalculationEmpty}
            materialOptions={materialOptions}
            last={index === fields.length - 1}
          />
        ))}
    </>
  );
};
