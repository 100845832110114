import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SharedHistoryContext } from './context/SharedHistoryContext';
import styles from './DescriptionEditor.module.scss';
import PlaygroundNodes from './nodes/Nodes';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import CalculationPlugin from './plugins/CalculationPlugin';
import ClickableLinkPlugin from './plugins/clickable-link-plugin/ClickableLinkPlugin';
import DragDropPaste from './plugins/drag-drop-paste-plugin/DragDropPastePlugin';
import HorizontalRulePlugin from './plugins/HorizontalRulePlugin/HorizontalRulePlugin';
import ImagesPlugin from './plugins/images-plugin/ImagesPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin/ListMaxIndentLevelPlugin';
import { ReactFormConnectorPlugin } from './plugins/ReactFormConnectorPlugin';
import TableActionMenuPlugin from './plugins/table-plugin/TableActionMenuPlugin';
import TableCellResizer from './plugins/table-plugin/TableCellResizer';
import { ToolbarPlugin } from './plugins/toolbar-plugin/ToolbarPlugin';
import './themes/PlaygroundEditorTheme.css';
import { TextEditorTheme } from './themes/TextEditorTheme';
import ContentEditable from './ui/ContentEditable';
import { validateUrl } from './utils/url';

type Props = {
  value: string | null;
  onChange: (value: string) => void;
  onSave: () => void;
  saveIsDisabled: boolean;
  isReadonly: boolean;
  isPrintViewEnabled: boolean;
};

export const InvestigationEditor: FC<Props> = ({
  value,
  onChange,
  onSave,
  saveIsDisabled,
  isReadonly,
  isPrintViewEnabled,
}) => {
  const { t } = useTranslation();
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const onError = (error: any) => {
    console.error(error);
  };

  const isEditable = !isReadonly && !isPrintViewEnabled;

  const initialConfig = {
    namespace: 'InvestigationEditor',
    nodes: [...PlaygroundNodes],
    theme: TextEditorTheme,
    onError,
    editable: isEditable,
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        {!isPrintViewEnabled && (
          <ToolbarPlugin onSave={onSave} saveIsDisabled={saveIsDisabled} isReadonly={isReadonly} />
        )}
        <div className={styles.editorContainer}>
          <TablePlugin />
          {isEditable && <TableCellResizer />}
          <RichTextPlugin
            contentEditable={
              <div
                className={clsx(
                  styles.editorTextbox,
                  isPrintViewEnabled ? '' : styles.editorTextboxMargin,
                )}
                ref={onRef}
              >
                <ContentEditable />
              </div>
            }
            placeholder={
              <div className={styles.editorPlaceholder}>
                {isEditable
                  ? t('InvestigationEditor.Placeholder')
                  : t('InvestigationEditor.ReadonlyPlaceholder')}
              </div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <AutoLinkPlugin />
          <LinkPlugin validateUrl={validateUrl} />
          <ClickableLinkPlugin />
          {!isPrintViewEnabled && <DragDropPaste />}
          <ReactFormConnectorPlugin onChange={onChange} initialValue={value} />
          <HorizontalRulePlugin />
          {!isPrintViewEnabled && <HistoryPlugin />}
          <TabIndentationPlugin />
          <ListPlugin />
          <CheckListPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <ImagesPlugin captionsEnabled={false} />
          <CalculationPlugin />
          {isEditable && floatingAnchorElem && (
            <TableActionMenuPlugin anchorElem={floatingAnchorElem} />
          )}
        </div>
      </SharedHistoryContext>
    </LexicalComposer>
  );
};
