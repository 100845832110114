import { Links } from '@/application/constants/links';
import { ExportRecipes } from '@/pages/authorized/export-recipes/ExportRecipes';
import { Promocodes } from '@/pages/authorized/promocodes/Promocodes';
import { InvestigationPage } from '@/pages/authorized/recipe/investigation-editor/InvestigationPage';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import { MaterialsPage } from './materials-page/MaterialsPage';
import { RecipeCalculationsPage } from './recipe-calculations-page/RecipeCalculationsPage';
import { RecipePage } from './recipe-page/RecipePage';
import { RootPage } from './root-page/RootPage';
import { UserProfilePage } from './user-profile-page/UserProfilePage';
import { WelcomePage } from './welcome-page/WelcomePage';

export const authorizedRoutes = () =>
  createBrowserRouter([
    {
      element: <RootPage />,
      children: [
        {
          path: '*',
          element: <Navigate to={Links.Authorized.Dashboard} />,
        },
        {
          path: Links.Authorized.Dashboard,
          element: <WelcomePage />,
        },
        {
          path: Links.Authorized.Folder,
          element: <WelcomePage />,
        },
        {
          path: Links.Authorized.Recipe,
          element: <RecipePage />,
          children: [
            {
              path: Links.Authorized.RecipeCalculations,
              element: <RecipeCalculationsPage />,
            },
            {
              path: Links.Authorized.RecipeInvestigation,
              element: <InvestigationPage />,
            },
          ],
        },
        {
          path: Links.Authorized.ExportRecipes,
          element: <ExportRecipes />,
        },
        {
          path: Links.Authorized.Materials,
          element: <MaterialsPage />,
        },
        {
          path: Links.Authorized.Promocodes,
          element: <Promocodes />,
        },
        {
          path: Links.Authorized.UserProfile,
          element: <UserProfilePage />,
        },
      ],
    },
  ]);
