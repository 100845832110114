import { getRecipeRoute } from '@/application/constants/links';
import FolderIcon from '@/assets/icons/folder-black.svg?react';
import { localFormat } from '@/helpers/date-helpers';
import {
  BatchIngredientDto,
  MaterialDto,
  PrintRecipeCalculationDto,
} from '@/services/api/api-client';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import QRCodeSVG from 'qrcode.react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PrintRecipeCalculation.module.scss';

type OwnProps = {
  materials?: MaterialDto[];
  printRecipeCalculationInfo: PrintRecipeCalculationDto;
};

export const PrintRecipeCalculation: FC<OwnProps> = ({ printRecipeCalculationInfo, materials }) => {
  const { t } = useTranslation();
  const {
    recipeName,
    recipeLastUpdatedAt,
    recipeCalculationName,
    recipeFolderName,
    recipeId,
    recipe,
    baseValuesSum,
    baseBatchValuesSum,
    overallValuesSum,
    overallBatchValuesSum,
  } = printRecipeCalculationInfo;

  const ingredients = recipe?.filter((x) => !x.isAddition);
  const additionalIngredients = recipe?.filter((x) => x.isAddition);

  const isBatchShouldBeRendered = baseBatchValuesSum || overallBatchValuesSum;

  const recipeLink = `${window.location.origin}${getRecipeRoute({ recipeId })}`;

  const renderIngredient = (ingredient: BatchIngredientDto, key: number) => {
    const material = materials?.find((x) => x.id === ingredient.material.id);
    return (
      <TableRow key={`${ingredient.material.id}_${key}`}>
        <TableCell style={{ borderBottom: 'none', width: '16px' }}>
          <div className={styles.rectangle} />
        </TableCell>
        <TableCell style={{ borderBottom: 'none', fontWeight: 400, fontSize: 16 }}>
          {material?.name}
        </TableCell>
        <TableCell style={{ borderBottom: 'none', fontWeight: 400, fontSize: 16 }} align='right'>
          {ingredient.value}
        </TableCell>
        {isBatchShouldBeRendered && (
          <TableCell style={{ borderBottom: 'none', fontWeight: 400, fontSize: 16 }} align='right'>
            {ingredient.batchValue}
          </TableCell>
        )}
      </TableRow>
    );
  };

  const renderResultRow = (
    title: string,
    baseValuesSum: number,
    baseBatchValuesSum: number | null,
  ) => (
    <TableRow
      key={`${title}_ingredients`}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell style={{ borderColor: '#000', fontWeight: 700, fontSize: 16 }} />
      <TableCell style={{ borderColor: '#000', fontWeight: 700, fontSize: 16 }}>{title}</TableCell>
      <TableCell style={{ borderColor: '#000', fontWeight: 700, fontSize: 16 }} align='right'>
        {baseValuesSum}
      </TableCell>
      {isBatchShouldBeRendered && (
        <TableCell style={{ borderColor: '#000', fontWeight: 700, fontSize: 16 }} align='right'>
          {baseBatchValuesSum}
        </TableCell>
      )}
    </TableRow>
  );

  const lastUpdatedAt = recipeLastUpdatedAt && localFormat(recipeLastUpdatedAt, 'Pp');

  return (
    <div className={styles.printRecipeCalc}>
      <div className={styles.printRecipeCalcContent}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <div>
            <Stack direction={'row'} alignItems={'baseline'}>
              <FolderIcon className={styles.printRecipeCalcFolderIcon} />
              <div className={styles.printRecipeCalcFolderName}>{recipeFolderName}</div>
            </Stack>
            <div className={styles.printRecipeCalcTitle}>{recipeName}</div>
            <div className={styles.printRecipeCalcSubtitle}>{recipeCalculationName}</div>
            <div className={styles.printRecipeCalcUpdatedAt}>{lastUpdatedAt}</div>
          </div>
          <QRCodeSVG value={recipeLink} />
        </Stack>
        {
          <TableContainer>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell align='right'>
                    {t('PrintRecipeCalculation.ColumnHeader.Recipe')}
                  </TableCell>
                  {isBatchShouldBeRendered && (
                    <TableCell align='right'>
                      {t('PrintRecipeCalculation.ColumnHeader.Batch')}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {ingredients?.map(renderIngredient)}
                {additionalIngredients &&
                  additionalIngredients.length > 0 &&
                  renderResultRow(
                    t('PrintRecipeCalculation.IngredientsSumTitle'),
                    baseValuesSum,
                    baseBatchValuesSum,
                  )}
                {additionalIngredients?.map(renderIngredient)}
                {renderResultRow(
                  t('PrintRecipeCalculation.AdditionalIngredientsSumTitle'),
                  overallValuesSum,
                  overallBatchValuesSum,
                )}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </div>
    </div>
  );
};
