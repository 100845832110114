import { useEffect } from 'react';

const useCtrlS = (callMethodIfCtrlS: () => void) => {
  useEffect(() => {
    // check if the key is "s" with ctrl key
    const keyDown = (event: KeyboardEvent) => {
      if ((event.key === 's' || event.key === 'ы') && (event.ctrlKey || event.metaKey)) {
        // prevent the browser from opening the save dialog
        event.preventDefault();

        // call our callback method
        callMethodIfCtrlS();
      }
    };

    // listen to keydown events
    document.addEventListener('keydown', keyDown);

    // stop listening on component destory
    return () => {
      document.removeEventListener('keydown', keyDown);
    };
  });
};
export default useCtrlS;
